.incubation_program_card_root {
    box-shadow: 0px 3px 20px rgb(48 48 48 / 10%);
    border-radius: 12px;
    background: #fff;
    cursor: pointer;
    margin-top: 15px;
}
.incubation_program_card_head {
    filter: drop-shadow(0px 3px 20px rgba(48, 48, 48, 0.1));
    border-radius: 12px 12px 0px 0px;
    height: 137px;
    padding: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.incubation_program_card_small svg {
    width: 0.6em;
}
.incubator_program_card_applicants .MuiAvatarGroup-avatar {
    width: 33px;
    height: 33px;
    font-size: 12px;
}
.incubator_program_card_footer {
    background: rgba(88, 1, 103, 0.2);
    border-radius: 0px 0px 12px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6.5px;
    margin-top: 20px;
}
.incubator_program_footer_pending {
    background: rgba(88, 1, 103, 0.2);
}
.incubator_program_footer_accept {
    background: rgba(38, 44, 91, 0.2);
}
.incubation_create_programFirst {
    height: 90%;
    margin-top: 15px;
    min-height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    border-radius: 12px;
    box-shadow: 0px 3px 20px rgb(48 48 48 / 10%);
}
.incubation_program_card_head p {
    background: #E2E8F0;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 25px;
    width: fit-content;
    /* height: 32px; */
    padding: 4px 17px;
}

.incubation_programm_searchBoxDiv input {
    width: 100%;
    border: none;
    background: #E4E4E4;
    border-radius: 100px 0 0 100px;
    padding: 10px;
    outline: none;
}
.incubation_programm_searchBoxDiv {
    width: 100%;
    display: flex;
    border-radius: 100px;
    background: #E4E4E4;
}
.incubation_programm_searchBoxDiv button {
    background: #FFFFFF;
    border: 1px solid #262C5B;
    box-sizing: border-box;
    box-shadow: 0px 3px 20px rgb(48 48 48 / 10%);
    border-radius: 100px;
    padding: 0 23px;
    text-shadow: 0px 3px 5px rgb(0 0 0 / 10%);
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}