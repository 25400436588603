.meet-box {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.1);
  border-radius: 10px;
}
.meet-eventhead {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #262c5b;
}
.meet-sidehead {
  color: #262c5b;
}
.meet-head-box {
  /* height: 110px; */
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.08);
  border-radius: 10px;
}
.wd_49 {
  width: 49% !important;
}
.meet-card-smalltext {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #989898;
}
.btn_pos {
  position: absolute;
  right: 30px;
  bottom: 15px;
}
