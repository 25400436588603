.neWpost-root p,
.neWpost-root h4 {
    margin: 0;
}

.dashboard01_corporate_news .MuiAvatar-root {
    border-radius: 20%;
    margin-right: 10px;
}

.dashboard01_corporate_news_row {
    margin: 10px 0;
}

.dashboard01_corporate_news a {
    color: inherit;
}

.newPost-create-main {
    display: flex;
    width: 100%;
    align-items: center;
    height: fit-content;
    transition: 0.5s ease;
    padding: 12px 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 10px;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.nPost_img_root_text {
    display: flex;
    align-items: center;
}

.newPostcreate {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background: rgba(242, 242, 242, 0.15);
    box-shadow: inset 0px 0px 4px rgb(117 117 117 / 25%);
    border-radius: 8px;
    padding: 8.6px 13px;
}

.post_root {
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 10px;
    padding: 0 24px 24px 24px;
    overflow: visible;
    margin-top: 0;
    margin-bottom: 50px;
}

.newPostImg-main {
    margin-right: 16px;
}

.newPost-select-buttons-root button {
    background: transparent;
    border: none;
    outline: none;
    color: #757575;
    margin-right: 1px;
}

.newPostcreate:hover {
    background: #d5d5d5;
    cursor: pointer;
    transition: all 0.2s ease;
}


/* comment csss */
/* .newComment-root {
    background: #f9f9f9;
    border-radius: 4px;
    padding: 10px;
} */
.comment-main {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.comment-icons {
    display: flex;
    align-items: flex-start;
}

.comment-more button {
    outline: none;
    background: transparent;
    border: none;
}

.comment-real {
    font-size: 13px;
    font-family: "Nunito-light";
    margin-top: 4px;
}

.comment-reply-like-main {
    width: 100%;
    display: flex;
    margin-top: 10px;
}

.comment-reply-like-main button {
    width: 30px;
    background: transparent;
    outline: none;
    border: none;
}

.comment-reply {
    display: flex;
    margin-right: 15px;
}

.comment-reply button svg {
    width: 0.88em;
    fill: #6d6d6d;
}

.comment-reply p {
    font-size: 14px;
    font-weight: 700;
}

.newComment-mainuser {
    display: flex;
    background: #f9f9f9;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    justify-content: space-between;
}

.replyComments-main {
    display: flex;
}

/* post modal */
.CreatePost-main {
    width: 540px;
    padding: 30px;
}

.createPost-head {
    width: 100%;
    padding: 12px 22px;
    border-bottom: 1px solid;
    position: relative;
}

.createPost-head button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    outline: none;
    border: none;
}

.createPost-head h4 {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
}

.createPost-body {
    padding: 18px 45px;
    position: relative;
}

.createPost-userInfo {
    display: flex;
    align-items: center;
}

.createPost-userInfo p {
    margin: 0;
    margin-left: 8px;
    font-size: 18px;
    font-weight: 600;
}

.createPost-post {
    margin-top: 10px;
}

.createPost-post textarea {
    width: 100%;
    height: 65px;
    background: rgba(202, 202, 202, 0.1);
    border: 1px solid rgba(159, 159, 159, 0.5);
    outline: none;
    font-size: 15px;
    margin-top: 14px;
    border-radius: 4px;
    padding: 4px;
}

.createPost-post textarea::placeholder {
    color: rgb(77, 74, 74);
    font-size: 15px;
}

.createPost-addicons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    border: 1px solid #757575;
    border-radius: 9px;
    padding: 6px 15px;
}

.createPost-addicons p {
    margin: 0;
    margin-left: 10px;
    font-size: 19px;
    font-weight: 600;
}

.addPost-icons button svg {
    height: 1.1em;
    width: 1.1em;
}

.createPost-postbtn {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.createPost-postbtn button {
    border: none;
    outline: none;
    background: linear-gradient(90deg, #68C1D2 0%, #2BD2B6 99.99%);
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    padding: 12px 30px;
}

.createPost-uploadImgDiv {
    width: 100%;
    height: 150px;
    border: 1px solid #d3d3d3;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 4px;
}

.createPost-uploadImgDiv input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
}

.createPost-uploadImgDiv label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.createPost-uploadImgDiv label svg {
    cursor: pointer;
    fill: #4cc9c5;
    width: 1.8em;
    height: 1.8em;
}

.make-bold,
.make-italic {
    width: 28px;
    border: 1px solid #404654;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    border-radius: 4px;
}

button.createpost-closeModal {
    position: absolute;
    right: 0;
    top: 0;
    outline: none;
    border: none;
    background: #cacaca;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.createpost-closeModal svg {
    fill: #404654;
    width: 0.7em;
    height: 0.7em;
}

.createpost-imageDiv {
    max-width: 100%;
    max-height: 300px;
}

.createpost-imageDiv img {
    width: 100%;
    height: 100%;
}

.feed_comment_input {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}

.feed_comment_input::placeholder {
    color: #000;
}

.post_avatar {
    cursor: pointer;
}

.post_header_root {
    display: flex;
    align-items: center;
}

.post_header_info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    /* padding: 0 18px; */
}

.post_avatar_main {
    width: 48px;
    height: 48px;
    position: relative;
    margin-right: 24px;
}

.post_avatar2 {
    width: 48px;
    height: 48px;
    border-radius: 6px;
    position: absolute;
    top: -13px;
}

.feed_image {
    width: 100%;
}

.post_total_likes_root {
    margin: 18px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.post_total_likes_img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.post_likeComment_root {
    height: 60px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    border-top: 1px solid #E4E4E4;
    border-bottom: 1px solid #E4E4E4;
}

.post_like_main,
.post_comment_main {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.post_like_main_img,
.post_comment_main_img {
    margin-right: 14px;
}

.post_comments_body_main {
    display: flex;
    margin-top: 16px;
}

.post_comments_body_img {
    margin-right: 24px;
}

.post_comments_body_img img {
    border-radius: 6px;
    width: 40px;
    height: 40px;
}

.post_comments_body_desc {
    margin-top: 8px;
}

.post_comments_body_likes_root {
    margin-top: 16px;
    display: flex;
}

.post_comments_body_likes_root p {
    margin-right: 24px;
}

.feed_post_writeComment_root {
    display: flex;
    align-items: flex-end;
}

.feed_post_writeComment_input {
    width: 100%;
    height: 48px;
    border: 1px solid #DBDBDB;
    display: flex;
    align-items: center;
    border-radius: 12px;
}

.feed_post_writeComment_input input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 14px 16px;
    border-radius: 12px;
}

.feed_post_writeComment_img img {
    border-radius: 6px;
}

.feed_post_writeComment_input button {
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}

.post_advertisment_root {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    padding: 14px 70px 14px 15px;
}

.post_advertisment_tag {
    background: #C1D1E6;
    border-radius: 2px;
    position: absolute;
    top: 8px;
    right: 9px;
    width: 20px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.post_advertisment_tag p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 12px;
    text-transform: uppercase;
    color: #000000;
}

.post_recommandation_root {
    box-shadow: 0px 4px 20px rgb(48 48 48 / 10%);
    border-radius: 10px;
}

.post_recommandation_head {
    padding: 16px 24px;
    background: rgba(226, 232, 240, 0.4);
    border-radius: 10px 10px 0px 0px;
}

.post_recomandation_body {
    padding: 16px 24px;
}

.post_recomandations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.post_recomandations_left {
    display: flex;
}

.post_recomandations_left img {
    border-radius: 8px;
}

/* animation in Create post */
.addPostHeight{
    min-height: fit-content;
    height: 140px;
    animation: addHeight 0.1s linear forwards;
}
.removePostHeight{
    min-height: fit-content;
    /* animation: removeHeight 0.5s linear forwards; */
}

.nPost_img_input_root textarea {
    width: 100%;
    border: none;
    outline: none;
}

@keyframes addHeight {
    0%{
        height: 65px;
    }
    20%{
        /* height: 60%; */
        height: 75px;
    }
    40%{
        height: 87px;
    }
    60%{
        height: 102px;
    }
    80%{
        height: 115px;
    }
    100%{
        height: fit-content;
    }
}
@keyframes removeHeight {
    0%{
        height: fit-content;
    }

    /* 50%{
        height: 80px;
    } */
    
    100%{
        height: 64px;
    }
}

.postUploadImg input {
    position: absolute;
    width: 0;
    opacity: 0;
    height: 0;
}

.postUploadImg label {
    margin: 0;
    cursor: pointer;
}

.nPost_img_input_root img {
    height: 332px;
    max-width: 100%;
}

.post_myPost_left{
    display: flex;
    align-items: center;
}
.post_myPost_left img{
    width: 43px;
    height: 40px;
}

.myPost_root_head {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
}

.myPost_root_head_heading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.myPost_root_head_back p {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nPost_img_root_text img,
.feed_post_writeComment_img img {
    width: 40px;
    height: 40px;
}
