.auth_root {
  /* background-color: #1d1d1d; */
  height: fit-content;
  min-height: 92.2vh;
  padding: 1rem 6rem;
}
.auth_upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auth_logo {
  width: 120px;
}
.auth_container {
  width: 40%;
  margin-top: 8vh;
}
.auth_header {
  text-align: center;
  margin-bottom: 3vh;
}
.auth_label {
  position: relative;
  width: 100%;
  color: #262c5b;
  font-weight: bolder;
}
.auth_form {
  text-align: center;
}
/* .auth_half{
  display: flex;
} */
/* option {
  background: black;
} */
.auth_input {
  width: 100%;
  height: 50px;
  background-color: transparent;
  /* border: 1px solid #404654; */
  border-radius: 3px;
  padding: 8px 0px 10px 0px;
  color: black;
  /* margin: 10px 0; */
}
.auth_head {
  margin: 0px 35px;
}
/* .color_black {
  background: black;
} */
.loginbtns {
  display: flex;
  justify-content: space-evenly;
}
.forbtn {
  /* margin: 0; */
  border: none;
  padding: 0.5rem;
  background: none;
  color: white;
  display: none;
  z-index: 1;
}
.backbtn {
  /* margin: 0; */
  border: none;
  padding: 0.5rem;
  background: none;
  color: white;
  z-index: 1;
}
button.backbtn svg {
  height: 35px;
  width: 70px;
  padding: 2px;
  border-radius: 3px;
  background: linear-gradient(
    90deg,
    rgb(187, 113, 2) 0%,
    rgb(221, 236, 4) 100%
  );
}
button.forbtn svg,
button.txt {
  height: 35px;
  width: 70px;
  padding: 2px;
  border-radius: 3px;
  background: linear-gradient(
    270deg,
    rgb(187, 113, 2) 0%,
    rgb(221, 236, 4) 100%
  );
  font-weight: 700;
}
button.txt {
  margin-top: 0.5rem;
}
.forget_pas_img {
  margin-bottom: 5vh;
  width: 80px;
}

.auth_input_password {
  width: 100%;
}
.auth_input_pas_btn {
  position: absolute;
  border: none;
  background-color: transparent;

  bottom: 32%;
  right: 5%;
}
.auth_form .MuiSvgIcon-root {
  fill: #9f9f9f !important;
}
.auth_link_div {
  margin: 20px 0;
  text-align: left;
}
.auth_link {
  text-decoration: none;
  color: #9f9f9f;
  /* font-family: "Nunito-light"; */
  cursor: pointer;
}
.auth_link:hover {
  color: white;
}
.auth_CTA {
  width: 100%;
}
.auth_login_btns {
  justify-content: space-evenly;
}
.auth_login_google {
  width: 100%;
}
/* ---------------Option Page------------------ */
.auth_option_img {
  width: 100%;
}
.auth_option {
  padding: 20px 0;
  width: 80%;
  margin: 0 auto;
  cursor: pointer;
}

/* ------------------------welcome page css------------------------*/
.welcome_cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.auth_img_cont {
  margin-bottom: 4rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
}
.welcome_name {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.register_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register_btn button {
  padding: 12px 18px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* ------------ thank you page css---------------- */
.thankyou_h {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.smooth-image {
  transition: opacity 1s;
}
.image-visible {
  opacity: 1;
}
.image-hidden {
  opacity: 0;
}

@media (max-width: 1150px) {
  .auth_root {
    padding: 1rem 5rem;
  }
  .auth_container {
    width: 60%;
  }
}
@media (max-width: 992px) {
  .auth_root {
    padding: 1rem 4rem;
  }
  .auth_logo {
    width: 130px;
  }
  .auth_container {
    width: 60%;
    margin-top: 15vh;
  }
}

@media (max-width: 768px) {
  .auth_root {
    padding: 1rem 3rem;
  }
  .auth_logo {
    width: 110px;
  }
  .auth_container {
    width: 80%;
    margin-top: 13vh;
  }
  .forget_pas_img {
    width: 60px;
  }
}

@media (max-width: 600px) {
  .auth_logo {
    width: 100px;
  }
  .auth_root {
    padding: 1rem 1rem;
  }
  .auth_container {
    width: 100%;
    margin-top: 8vh;
  }
  .auth_header {
    margin-bottom: 3vh;
  }
  .auth_link_div {
    margin: 10px 0;
    text-align: left;
  }
  .auth_option h5 {
    font-size: 12px;
  }
  .auth_option {
    width: 100%;
  }
}
@media (max-width: 420px) {
  .auth_option_root {
    padding: 20px 0 !important;
  }
}

/* @media (max-width:1150px){
    
}
@media (max-width:992px){
  
}

@media (max-width:768px){
  
}

@media (max-width:600px){
  
    
} */

.auth_container2 {
  width: 73%;
  margin-top: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.auth_container3 {
  margin-top: 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50vw;
  top: 50vh;
  background: black;
  padding: 5vw;
  border-radius: 10px;
}
.auth_container3 h1,
.auth_container3 p {
  margin-bottom: 20px;
}

.auth_container4 {
  width: 40%;
  margin-top: 2vh;
}
.auth_root2 {
  background-color: #1d1d1d;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 92.2vh;
  padding: 3rem 6rem;
}
.auth_root2 ::placeholder {
  color: #fff;
}
.auth_root2 :-ms-input-placeholder {
  color: #fff;
}

.auth_root2 ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.auth_reg_form {
  width: 65%;
}

.auth_upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.profilePhoto {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profilePhoto input[type="file"] {
  display: inline-block;
  width: 95px;
  height: 95px;
  padding: 95px 0 0;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  background: url("https://cdn1.iconfinder.com/data/icons/hawcons/32/698394-icon-130-cloud-upload-512.png")
    50% no-repeat #404654;
  border-radius: 50%;
  background-size: 70px 70px;
  outline: none;
}
.profilePhoto label {
  height: 100px;
}
.auth_upload input[type="file"] {
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.com_logo_ {
  border: 1px solid #9f9f9f;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #404654;
  color: #9f9f9f;
  font-size: 0.7rem;
  cursor: pointer;
  outline: #fff;
}

.auth_form {
  display: flex;
  flex-direction: column;
}
.color_black {
  color: black;
}
.com_date_loc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reg_form .auth_input {
  height: 36px;
  margin: 8.6px 0;
}
.reg_form .auth_textarea {
  padding: 8px 0px 10px 0px;
  min-height: 100px;
}

/* .loginbtns {
  display: flex;
  align-items: center;
  margin-top: 15px;
} */
.loginbtns a {
  text-decoration: none;
}
.reg_form ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.black_CTA {
  border: 3px solid white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  /* font-family: Nunito-light; */
  margin-top: 10px;
  text-align: center;
  color: white !important;
  background: #1d1d1d;
}
.gradient_CTA {
  border: none;
  font-size: 14px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 16px !important;
  margin-top: 10px;
  color: #ffffff !important;
  text-align: center;
  width: fit-content;
  background: #000000;
  border-radius: 30px;
  transition: 0.6s ease;
}

/* .loginbtns .gradient_CTA {
  padding: 12px 17px;
}
.loginbtns .black_CTA {
  padding: 9px 17px;
} */

.auth_root2 .MuiStepper-root {
  margin-bottom: 2rem;
  width: 100%;
}

.auth_select option {
  /* font-family: Nunito-light; */
  color: black;
}

.auth_news {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding: 0.3rem 0; */
  color: #777;
}
.auth_news a {
  color: white;
}

.auth_news input[type="checkbox"] {
  margin-right: 0.4rem;
}

i.fas.fa-toolbox,
i.fas.fa-address-card {
  font-size: 1.3em;
}

.auth_file_choose {
  color: white;
}

.w_49 {
  width: 49%;
}
.w_25 {
  width: 25%;
}

.w_46 {
  width: 46%;
}
.send_otp_btn_auth {
  display: flex;
  margin-bottom: 4.5rem;
}
.send_otp_btn_auth button {
  padding: 8px 16px;
}
.ForwardRef\(StepConnector\)-line-7 {
  width: 8vw !important;
}

.register_btn a {
  text-decoration: none;
}

/* investor css */
.auth_otp_btn {
  text-align: start;
  padding: 0.6rem 0;
}
.auth_otp_btn button {
  padding: 8px 16px !important;
  margin-top: 0 !important;
}
.verify_otp_auth {
  display: flex;
  align-items: center;
}
.verify_otp_auth button {
  margin-left: 1.5rem;
}
.auth_associate {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}
.auth_associate label {
  margin-right: 10px;
  font-size: 1.1rem;
  cursor: text;
}
.auth_associate input[type="checkbox"] {
  height: 18px;
  width: 18px;
  margin-top: 5px;
}
.add_entity button,
.auth_entities button {
  background: transparent;
  border-radius: 50%;
  height: 37px;
  width: 37px;
  text-align: center;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
}
.add_entity .fa-plus-circle,
.auth_entities .fa-trash {
  color: #ffffff !important;
  text-align: center;
}

.add_entity button:hover,
.auth_entities button:hover {
  color: #ffffff !important;
  cursor: pointer;
  box-shadow: 0px 1px 4px 3px #404654;
}
.auth_entities {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 10px;
  margin: 8px 0;
  padding: 8px 10px;
}
.auth_entities div {
  text-align: start;
}
.auth_entities p {
  padding: 0;
  margin: 0;
}
.auth_entities a {
  color: #0090ff !important;
  text-decoration: none;
}
.auth_entities button {
  font-size: 1rem;
}
.authIdCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 1rem;
}
.authIdCardFirst {
  width: 95%;
  text-align: start;
}
.authIdCardSecond {
  width: 10%;
  position: relative;
}
.authIdCardSecond i {
  cursor: pointer;
}
.auth_root2 .searchWrapper {
  border: 1px solid #404654 !important;
}
.authIdCard #investor_id {
  opacity: 0;
  position: absolute;
  right: 10px;
  top: 0;
  width: 40px;
  height: 40px;
  text-align: center;
}
.investor_logo {
  width: 50px;
  height: 50px;
  text-align: center;

  color: white;
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
}
.investor_logo:hover {
  border-radius: 50%;
  color: #757575;
  cursor: pointer;
}

.auth_root2 .chip {
  background-color: transparent;
  color: #4ad7d1;
  border: 1px solid #9f9f9f;
  border-radius: 15px;
}
.auth_root2 .closeIcon {
  border: none;
}
.document_upload .MuiSvgIcon-root {
  fill: #1d1d1d;
}
.auth_upload .companyLogo {
  max-width: 100px;
  max-height: 100px;
}
.profile_pic_block {
  position: relative;
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 15px;
}
.profile_pic {
  width: 100%;
  height: auto;
}
.auth_input option {
  /* font-family: "Nunito-light" !important; */
  /* background-color: black; */
  color: black;
  line-height: 10px;
  margin: 5px 0;
}
.auth_root2 .MuiInputBase-input {
  color: #ffffff;
}
.auth_root2 .MuiOutlinedInput-root {
  height: 35px;
  margin: 15px 0;
}
.auth_root2 .multiSelectContainer {
  padding: 8px 0;
}
.auth_root2 .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #4e4e4e;
}
.auth_root2 .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #4e4e4e;
}
.auth_root2
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #ffffff;
}

.auth_root2 .MuiMenu-paper {
  left: 20% !important;
  width: 50% !important;
  top: 40% !important;
}

.valuation_input {
  position: relative;
  display: inline;
}

@media (max-width: 1150px) {
  .auth_reg_form {
    width: 75%;
  }

  .auth_container2,
  .auth_container3 {
    width: 75%;
  }
  .auth_root2 .MuiPaper-root,
  .MuiMenu-paper {
    left: 20% !important;
    width: 60% !important;
    top: 40% !important;
  }
}

@media (max-width: 992px) {
  .auth_reg_form {
    width: 85%;
  }

  .auth_root2 {
    padding: 2rem 4rem;
  }
  .auth_root2 .MuiPaper-root,
  .MuiMenu-paper {
    left: 20% !important;
    width: 65% !important;
    top: 40% !important;
  }

  .multiSelectContainer {
    padding: 7.8px 0;
  }
  .auth_news label {
    color: #9f9f9f;
    font-size: 14px;
  }
  .text-align-left {
    text-align: left;
  }
}

@media (max-width: 992px) {
  .auth_reg_form {
    width: 85%;
  }
  .auth_root2 {
    padding: 2rem 4rem;
  }
  .auth_container2,
  .auth_container3 {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .auth_reg_form {
    width: 85%;
  }
  .w_25 {
    width: 40%;
  }
  .auth_root2 {
    padding: 2rem 3rem;
  }

  .auth_container2,
  .auth_container3 {
    width: 85%;
  }

  .auth_root2 .MuiStepper-root {
    margin: 0 -10px 2rem;
  }
  .auth_root2 .MuiPaper-root,
  .MuiMenu-paper {
    left: 20% !important;
    width: 70% !important;
    top: 40% !important;
  }
}

@media (max-width: 600px) {
  .auth_reg_form {
    width: 95%;
  }

  .auth_root2 {
    padding: 1rem 1rem;
  }

  .auth_container2,
  .auth_container3 {
    width: 100%;
    padding: 0;
  }
  .auth_container3 {
    padding: 1vw;
    margin: 1vw;
  }
  .auth_root2 .MuiPaper-root,
  .MuiMenu-paper {
    left: 10% !important;
    width: 80% !important;
    top: 35% !important;
  }

  .w_25 {
    width: 50%;
  }

  .auth_container2,
  .auth_container3 {
    width: 100%;
    padding: 0;
  }
  .auth_container3 {
    padding: 1vw;
    margin: 1vw;
  }
}
.MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
  border: 1px solid #444;
  border-radius: 7px;
}
input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart.MuiOutlinedInput-inputAdornedStart {
  color: black;
}
.p_small_whiteGray,
.p_small_grey,
.p_small_white {
  color: rgba(255, 255, 255, 0.877);
}

.p_small_black {
  color: black;
}
.btn {
  border: none;
  padding: 0.5rem;
  background: none;
  color: white;
  z-index: 1;
  height: 35px;
  width: 70px;
  padding: 2px;
  border-radius: 3px;
  background: linear-gradient(
    90deg,
    rgb(187, 113, 2) 0%,
    rgb(221, 236, 4) 100%
  );
}
.h2_white_35,
.p_small_grey_bold {
  color: #fff;
}
