.event_dashboard_02 {
  display: flex;
  width: 100%;
  flex-direction: column;
  /* width: fit-content; */
  justify-content: center;
  /* align-items: center; */
  text-align: center;
}
.event_dashboard_btn_01 {
  padding: 8px;
  border: none;
  outline: none;
  background: no-repeat;
  width: 100%;
  transition: all 0.5s ease;
  padding-left: 30%;
  text-align: left;
}
.event_dashboard_btn_01:hover {
  background: rgba(226, 232, 240, 0.5);
}
.event_dashboard_btn_01_selected {
  background: rgba(226, 232, 240, 0.5);
}
.event_card_dSetail span svg {
  width: 0.5em;
  fill: #262c5b;
}
.event_card_dSetail span {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #262c5b;
  margin-left: 5px;
}
.event_card_dSetail {
  padding: 0;
  display: flex;
  align-items: center;
}
.tab_head {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #121843;
  margin: 30px 30px 8px;
}
/* .inactive_program_tabs{
  padding: 8px 15px 8px 2vw!important;
} */
.inactive_program_tabs > p {
  color: rgba(86, 86, 86, 0.5) !important;
}
.inactive_program_tabs .dashboard01_tab_svg > svg {
  fill: rgba(86, 86, 86, 0.5) !important;
}
.dashboard01_tabs .Mui-selected .dashboard01_tab_svg svg {
  fill: #000000 !important;
}
