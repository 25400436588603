.dashboard_program_box{
    background: #FFFFFF;
    border-radius: 12px;
    height: 100%;
}
.dashboard_program_boxhead{
    padding: 8px 24px;
    background: rgba(226, 232, 240, 0.6);
    border-radius: 12px 12px 0px 0px;
}
.dashboard_program_boxcontent{
    padding: 16px 24px;
    
}
