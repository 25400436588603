.incubator_insights_head_sct {
    border: none;
    background: transparent;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    outline: none;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    color: #262C5B;
}

.incubator_insight_head_money_root {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 12px;
}

.incubator_insight_head_bd {
    border-bottom: 1px dashed #989898;
    width: fit-content;
}

.incubator_insight_rupee {
    font-size: 21px;
    padding-right: 8px;
}

.incubator_insight_head_money_foot {
    background: rgba(226, 232, 240, 0.6);
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
}

.incubator_insights_head{
    border-bottom: 1px solid #e4e4e4;
}

.incubator_insights_unpaid_ select {
    background: #E2E8F0;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 8px;
    padding: 9px 24px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    border-right: 20px solid #E2E8F0;
}

.incubator_insights_estimate_main {
    background: #E2E8F0;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 8px;
    padding: 9px 24px;
}

.incubator_insights_appCard_root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 16px;
    height: 100%;
}

.p-6{
    padding: 0 0 0 2rem;
}