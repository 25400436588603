.profle-coverimg{
    width: 100%;
    height: 142px;
}
.profile-topbar{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.08);
    border-radius: 0px 0px 10px 10px;
    position: relative;
}
.profile-topbar .MuiAvatar-root{
    width: 102px !important;
    height: 102px !important;
}
.profile-img{
    position: absolute !important;
    top: -61px;
    background-color: rgba(0,0,0,1);
}
.profile-editbtn{
    background: #FFFFFF;
    height:48px;
    width: 48px;
    display: flex;
    border-radius: 50%;
    box-shadow: 0px 3px 5px rgb(0 0 0 / 10%);
    justify-content: center;
    align-items: center;
}
.profile-detail{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.08);
    border-radius: 0px 0px 10px 10px;
}
.profile-body2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #757575;
}
.profile-greybox{
    background: rgba(226, 232, 240, 0.4);
    border-radius: 10px 10px 0px 0px;
}