.mentor_tasks_root {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 16px;
}

.mentor_tasks_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mentor_tasks_bodyleft {
    padding-right: 16px;
    border-right: 1px solid #E4E4E3;
}

.mentor_tasks_bodyCmpName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F8F9FA;
    border: 0.1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    border-radius: 8px;
    transition:all 0.4s linear;

}

.mentor_tasks_bodyCmpprf {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mentor_tasks_bodyCmpprf img {
    margin: 4px 8px;
    border-radius: 50%;
    border: 0.5px solid rgba(38, 44, 91, 0.5);
    width: 24px;
    height: 24px;
}

.mentor_tasks_bodyCmpName span {
    padding-right: 10px;
}

.mentor_tasks_bodyProgress {
    background: rgba(255, 111, 30, 0.1);
    border-radius: 10px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mentor_tasks_bodyProgress h5 {
    padding-right: 11px;
}

.mentor_tasks_mainTask {
    background: rgba(248, 249, 250, 0.35);
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 16px;
}
.mentor_tasks_mainTask:last-child{
    margin-bottom: 0;

}
.mentor_tasks_maintask_name,
.mentor_tasks_maintask_due {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mentor_tasks_maintask_due{
    margin-top: 6px;
}
.mentor_tasks_select_root{
    margin-bottom: 21px;
    position: relative;
    transition:all 0.4s linear;
}
.mentor_tasks_options_root {
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 1px 3px -1px rgb(0 0 0 / 40%);
    position: absolute;
    z-index: 768687687;
    background: white;
    max-height: 160px;
    overflow-y: scroll;
    width: 100%;
    transition:all 0.3s linear;
}

.hide{
    opacity: 0;
}
.show{
    animation: showTask 0.3s linear forwards;
}

@keyframes showTask {
    0%{
        opacity: 0;
    }
    20%{
        opacity: 0.2;
        
    }
    40%{
        opacity: 0.4;
        
    }
    60%{
        opacity: 0.6;
        
    }
    80%{
        opacity: 0.8;
        
    }
    100%{
        opacity: 1;

    }
}

.mentor_tasks_options img {
    margin: 4px 8px;
    border-radius: 50%;
    border: 0.5px solid rgba(38, 44, 91, 0.5);
    width: 24px;
    height: 24px;
    height: 24px;
    transition:all 0.4s linear;
}
.mentor_tasks_options {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
    padding: 4px 0;
    transition:all 0.4s linear;
}
.mentor_tasks_options:last-child{
    border-bottom: none;

}
.mentor_tasks_options:hover{
    background-color: #F8F9FA;
    transition:all 0.3s linear;

}
.mentorDownbtn{
    transition:all 0.3s linear !important;
}
.rotateDown{
    transition:all 0.3s linear !important;
    transform: rotate(-180deg);
}
