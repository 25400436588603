.inlineEditInputHead{
     background-color: transparent;
     border: 0;
     padding: 6px;
     font-family: Inter;
     font-style: normal;
     font-weight: 500;
     font-size: 18px;
     line-height: 20px;
     letter-spacing: 0.75px;
     color: #000000;
}
.inlineEditInput{
     background-color: transparent;
     border: 0;
     padding: 8px;
}


.inlineEditInputHead:hover,.inlineEditInput:hover {
     background-color: #d3d3d3;
     cursor: pointer;
}

.inlineEditTextarea{
    resize: none;
    /* overflow: hidden; */
    min-height: 3.5em;
    max-height: 3.5rem;
    background-color: transparent;
    border: 0;
    padding: 8px;
    width: 100%;
    
}

.inlineEditTextarea:hover{
    background-color: #d3d3d3;
    cursor: pointer;
}

.inlineEditTextarea:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color; 
}
