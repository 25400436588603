.startup_incubation_viewProprgam_head {
    border-bottom: 1px solid #E7E7E7;
    padding: 4px 15px;
    padding-top: 13px;
}
.startup_incubation_viewProprgam_desc,
.startup_incubation_viewProprgam_highlites,
.startup_incubation_viewProprgam_benElig {
    padding: 15px;
    border-bottom: 1px solid #E7E7E7;
}
.startup_incubation_viewProprgam_desc p, 
.startup_incubation_viewProprgam_benefit ul li{
    color: var(--fifthBlack);
}
.startup_incubation_viewProprgam_high_ul ul,.startup_incubationeligibility ul,
.startup_incubation_docs ul{
    list-style-type: decimal;
    padding: 4px 17px;
}
.startup_incubation_viewProprgam_benefit ul {
    padding: 4px 17px;
}
.startup_incubation_viewProprgam_documents {
    padding: 15px;
    padding-bottom: 20px;
}
.startup_incubation_viewProprgam_infor {
    padding: 18px;
}
.startup_incubation_viewProprgam_date {
    background: rgba(226, 232, 240, 0.4);
    border-radius: 4px;
    padding: 14px 13px;
    margin-bottom: 12px;
}
.startup_incubation_viewProprgam_date span svg {
    width: 0.77em;
    margin-right: 4px;
}.startup_incubation_viewProprgam_date span svg {
    width: 0.77em;
    margin-right: 4px;
}
.startup_incubation_viewProprgam_btn button {
    border: 3px solid #262C5B;
    box-sizing: border-box;
    border-radius: 100px;
    background: transparent;
    outline: none;
    padding: 9px 34px;
    margin-top: 9px;
    color: #262c58;
}
.startup_incubation_app_info {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 10%);
    border-radius: 20px;
    padding: 21px;
    display: flex;
    flex-direction: column;
}
.startup_incubation_sDae {
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
}
.startup_incubation_startDate {
    background: linear-gradient(259.04deg, #370041 4.27%, #121843 99.98%);
    border-radius: 8px 0px 0px 8px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    text-align: center;
}
.startup_incubation_dText {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 53px;
}
.startup_incubation_heigth {
    height: 40px;
    width: 2px;
    margin-left: 19px;
    background: #e4e4e4;
}
.incubation_profile__head_card2_root img {
    /* max-height: 300px;  */
    height: 100%;
}
.incubation_profile__head_card2_root {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}
.incubationprofile_card1_link a {
    word-break: break-all;
}
.startup_incubation_viewProprgam_high_ul ul>li,
.startup_incubation_viewProprgam_benefit ul>li{
    word-break: break-word;
}
.startup_uploaddoc{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.1);
    border-radius: 8px;
    padding: 11px 22px;
}
.authIdCard2{
    display: flex;
    justify-content: space-between;
    align-items: center;
}