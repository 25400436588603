.val-dash-percentbox{
    width:32px;
    height: 32px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(./../../../Assets/images/image-back-dash.png);
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    padding:10px 5px;
    margin-right: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}