.incubator_create_program_head h4{
    color: var(--fourthBlack);
}
.incubator_create_program_main {
    width: 100%;
    background: white;
    padding: 85px 120px;
}
.program_upload_img {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 10%);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-top: 14px;
    /* cursor: pointer; */
}
.program_upload_img input {
    position: absolute;
    left: 0;
    cursor: pointer;
    width: 0;
    height: 0;
    opacity: 0;
}
.program_upload_img p {
    margin: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* details {
	position: relative;
	width: 300px;
	margin-right: 1rem;
}

details[open] {
	z-index: 1;
}

summary {
	padding: 1rem;
	cursor: pointer;
	border-radius: 5px;
	background-color: #ddd;
	list-style: none;
}

summary::-webkit-details-marker {
	display: none;
}

details[open] summary:before {
	content: '';
	display: block;
	width: 100vw;
	height: 100vh;
	background: transparent;
	position: fixed;
	top: 0;
	left: 0;
}

summary:after {
	content: '';
	display: inline-block;
	float: right;
	width: .5rem;
	height: .5rem;
	border-bottom: 1px solid currentColor;
	border-left: 1px solid currentColor;
	border-bottom-left-radius: 2px;
	transform: rotate(45deg) translate(50%, 0%);
	transform-origin: center center;
	transition: transform ease-in-out 100ms
}

summary:focus {
	outline: none;
}

details[open] summary:after {
	transform: rotate(-45deg) translate(0%, 0%);
} 

ul {
	width: 100%;
	background: #ddd;
	position: absolute;
	top: calc(100% + .5rem);
	left: 0;
	padding: 1rem;
	margin: 0;
	box-sizing: border-box;
	border-radius: 5px;
	max-height: 200px;
	overflow-y: auto;
}

li {
	margin: 0;
	padding: 1rem 0;
	border-bottom: 1px solid #ccc;
}

li:first-child {
	padding-top: 0;
}

li:last-child {
	padding-bottom: 0;
	border-bottom: none;
}*/

/* .select {
    display:flex;
    flex-direction: column;
    position:relative;
    height:40px; 
    margin-top: 10px;
  }
  
  .select .option {
    padding:0 30px 0 10px;
    min-height:40px;
    display:flex;
    align-items:center;
    background:#fff;
    border-bottom: 1px solid #98B3D6;
    position:absolute;
    top:0;
    width: 100%;
    pointer-events:none;
    order:2;
    z-index:1;
    transition:0.4s ease-in-out;
    box-sizing:border-box;
    overflow:hidden;
    white-space:nowrap;
    text-align: center;
    margin:0;
    color: #636363;
  }
  
  .select .option:hover {
    background: #EFEFEF;
    color:#000;
  }
  
  .select:focus .option {
    position:relative;
    pointer-events:all;
  }
  
  .select input {
    opacity:0;
    position:absolute;
    left:-99999px;
  }
  
  .select input:checked + p {
    order: 1;
    z-index:2;
    background:#fff;
    border-top:none;
    position:relative;
  }
  
  .select  input:checked + p:after {
    content:'';
    width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #262C5B;
    position:absolute;
    right:10px;
    top:calc(50% - 2.5px);
    pointer-events:none;
    z-index:3;
  }
  
  .select input:checked + p:before {
    position:absolute;
    right:0;
    height: 40px;
    width: 40px;
    content: '';
  } */