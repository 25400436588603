.feedback-body1{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #303030;
}
.feedback-starlabel{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    width: 32px;
    /* margin-left: 14px; */
    color: #989898;
}
.participant_feadback_body .MuiRating-root label {
    width: 36px !important;
    /* margin-left: 13px; */
}
label.MuiRating-label.MuiRating-pristine {
    display: none;
}