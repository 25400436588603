.participants_profile_head {
    padding-bottom: 14px;
    border-bottom: 1px solid #E4E4E4;
    margin-bottom: 24px;
}

.dashboard01_connection_profile_head_img img {
    width: 64px;
    height: 64px;
    border-radius: 12px;
}

.partipant_profile_main_left {
    background: white;
    padding: 40px 24px;
}

.partipant_profile_main_left_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard01_connection_profile_head_img {
    display: flex;
    align-items: center;
}

.participant_profile_right_rating_main {
    width: 90px;
    height: 90px;
    background: rgba(226, 232, 240, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
}

.participants_right_main_founder_body img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.participants_right_main_founder_body {
    display: flex;
    align-items: center;
}

.participants_right_main_founder_root {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 10px;
    padding: 16px 24px;
}

.participants_profile_root_highligts_head {
    background: rgba(226, 232, 240, 0.4);
    border-radius: 10px 10px 0px 0px;
    padding: 20px 28px;
}

.participants_profile_root_highligts_ftd {
    padding: 24px 0 0 29px;
    width: 55%;
}
.participants_profile_root_highligts_td {
    padding: 24px 29px 0 0;
}

.INR {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #AFAFAF;
}

.INR50{
    color: #404654 !important;
}

.partipant_profile_main_review_main {
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
}

.partipant_profile_main_review_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.incubator_analytics_rating_pr {
    height: 100%;
    background: linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%);
    border-radius: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* padding: 0 15px; */
}