.feedAllPost_tabs_root {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #E4E4E4;
}
.feedAllPost_tabpanel_root .MuiBox-root{
    padding: 24px 0 !important;
}

.feedAllPost_tab {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: center !important;
    letter-spacing: 1.25px !important;
    text-transform: uppercase !important;
    color: #989898 !important;
    padding: 0 16px !important;
}

.feedAllPost_tab.Mui-selected{
    color: black !important;
}

span.feedAllPost_tab_span {
    height: 24px;
    width: 2.229px !important;
    background: #989898;
}

.feedAllPost_tabs .MuiTabs-indicator {
    display: none !important;
}
.feedAllPost_tabs .MuiTabs-flexContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}