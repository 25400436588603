.signup-checkbox a {
  color: white;
}
.registration {
  margin-top: 10vh;
  /* height: 80vh; */
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.reg_form {
  padding: 20px;
}
.regform {
  align-items: center;
  margin: auto;
  margin-top: 5vh;
  background: black;
  width: 55vw;
  padding: 4vh;
  border-radius: 5px;
}
.chip{
  background-color: #262C5B;
}