.currentFunHead {
  width: 100%;
  padding: 30px 0 0 25px;
}

/* .tabs-content .MuiSvgIcon-root {
  color: #f9512d;
} */

.currentFunHead h1 {
  font-size: 18px;
  font-weight: 700;
}

.currentFunHead p {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}

.currentFunding hr {
  border: 1.3px solid #757575;
  width: 95%;
  margin: 0 auto;
}

.valuation-transaction {
  padding-left: 21px;
  margin-top: 20px;
}

.valuation-transaction:first-child {
  padding-bottom: 10px;

  margin-top: 20px;
}

.valuation-transaction:nth-child(2) div {
  padding: 3px 5px;
}

.valuation-transaction:first-child div {
  display: flex;
  align-items: center !important;
}

.valuation-transaction:first-child p {
  margin: 0 !important;
}

.valuation-transaction h1 {
  font-size: 18px;
  font-weight: 600;
}

.valuation_input_label {
  font-family: Inter;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
  color: #000000 !important;

}
form.questionare_form_main .MuiFormControlLabel-label {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #404654;
}

.mb-3.selectElement {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.mb-3.selectElement label {
  width: 100%;
  cursor: text !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
  color: #000000 !important;
}

.mb-3.selectElement select {
  width: 100%;
  margin-top: 10px;
  padding: 8px !important;
  font-weight: 600;
  font-size: 14px;
  /* font-family: Inter; */
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
  color: #000000 !important;
}

.mb-3.selectElement select option{
  font-family: Inter;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
  color: #000000 !important;
}

.valuation-transaction div div p {
  font-size: 14px;
}

table.pastfundingtable {
  border: none;
  width: 100%;
  margin-bottom: 140px;
  display: flex;
  flex-direction: column;
}

table.pastfundingtable thead,
table.pastfundingtable tr,
table.pastfundingtable th,
table.pastfundingtable td,
table.pastfundingtable tbody {
  border: none;
}

thead.pastfundingthead tr th {
  padding: 20px 10px;
  text-align: center;
}

thead.pastfundingthead tr,
tbody.pastfundingtbody tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

thead.pastfundingthead tr th,
tbody.pastfundingtbody tr td {
  width: 22%;
}

thead.pastfundingthead tr th:nth-child(5),
tbody.pastfundingtbody tr td:nth-child(5) {
  width: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
}

thead.pastfundingthead tr {
  border-bottom: 1px solid black;
}

tbody.pastfundingtbody tr:first-child td {
  padding: 25px 0px 10px 0;
  text-align: center;
}

tbody.pastfundingtbody tr td {
  padding: 10px 0px 10px 0;
  text-align: center;
}

tbody.pastfundingtbody tr td input {
  max-width: 90%;
  min-width: 80px;
  height: 37px;
  border-radius: 5px;
  border: 1px solid #757575;
  padding: 0 10px;
}

.pastfundingtbody button.addrowsbtn {
  background: linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%);
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 50%;
  color: wheat;
  /* font-size: 11px; */
  font-weight: 700;
}

.pastfundingtbody button.addrowsbtn .MuiSvgIcon-root {
  color: white !important;
}

.pastfundingtbody button.addrowsbtn:hover {
  color: black;
}

.pastfundingtbody .removebtn {
  background: white;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 50%;
  color: #fd6821;
  /* font-size: 11px; */
  width: 40px;
  font-weight: 700;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease-in-out;
}
.pastfundingtbody .removebtn:hover {
  background: linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%);
  color: white;
}

.pastfundingtbody hr {
  margin: 0;
  padding: 0;
}