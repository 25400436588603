.leases_active_card_root {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 12px;
    display: flex;

}

.leases_active_card_left_black {
    width: 13px;
    background: rgba(88, 1, 103, 0.2);
    border-radius: 12px 0px 0px 12px;
}

.leases_active_card_main {
    width: 100%;
    padding: 24px 0;
}

.leases_active_card_main_profile {
    display: flex;
    align-items: center;
}

.leases_active_card_main_profile img{
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.leases_active_card_right_back {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.img_cabin {
    width: 24px;
    height: 24px;
    background: rgba(226, 232, 240, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.img_cabin img{
    width: 16px;
    height: 16px;
}

.leases_active_card_right_cabin {
    display: flex;
    margin-bottom: 10px;
}

.leases_active_card_month {
    display: flex;
    align-items: center;
}

.leases_active_card_month>svg{
    width: 16px;
    height: 16px;
}

.leases_active_card_month_left {
    display: flex;
    align-items: center;
}

.leases_active_card_main_left {
    border-right: 2px solid #C4C4C4;
}