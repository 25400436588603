.notify[type="checkbox"]{
   width: 5%;
}

.todos:hover{
   background-color: #d3d3d3;
   cursor: pointer;
}

.todo-input{
   height: 2.3rem;
}