.startup_assets_head_addToCard {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    cursor: pointer;
}

.startup_assets_head_addToCard img {
    width: 24px;
    height: 24px;
}