.notFound_root{
    /* background-color: #1d1d1d; */
    /* height: 100vh; */
    display: flex;
    justify-content: center;
}
.logo_404{
    width: 120px;
    margin: 1rem 6rem;
}
.container_404{
    text-align: center;
    width: fit-content;
    margin-top: 10vh;
}
.container_404 button{
    padding: 10px 60px;

}