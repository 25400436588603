.user_incubation_course_slide {
    background: rgba(88, 1, 103, 0.1);
    border-radius: 20px 0px 0px 20px;
    width: 22px;
}
.user_incubation_course_leftSide {
    height: 100%;
    border-left: 1px solid #e6e6e6;
    padding-left: 20px;
}
