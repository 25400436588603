.auth_root {
  background-color: #1d1d1d;
  height: fit-content;
  min-height: 92.2vh;
  padding: 1rem 6rem;
}

.auth_logo {
  width: 120px;
}
.auth_container {
  width: 40%;
  margin-top: 8vh;
}
.auth_header {
  text-align: center;
  margin-bottom: 3vh;
}
.auth_label {
  position: relative;
  width: 100%;
}
.auth_form {
  text-align: center;
}
.auth_input {
  width: 100%;
  height: 30px;
  background-color: transparent;
  /* border: 1px solid #404654; */
  border-radius: 3px;
  padding: 0 10px;
  color: black;
  /* margin: 10px 0; */
}
.forget_pas_img {
  margin-bottom: 5vh;
  width: 80px;
}

.auth_input_password {
  width: 100%;
}
.signup-form-frgetPas {
  position: relative;
}
.auth_input_pas_btn {
  position: absolute;
  border: none;
  background-color: transparent;
  bottom: 8px;
  right: 5%;
}
.auth_form .MuiSvgIcon-root {
  fill: #9f9f9f !important;
}
.auth_link_div {
  margin: 20px 0;
  text-align: left;
}
.auth_link {
  text-decoration: none;
  color: #9f9f9f;
  font-family: "Nunito-light";
  cursor: pointer;
}
.auth_link:hover {
  color: white;
}
.auth_CTA {
  width: 100%;
}
.auth_login_btns {
  justify-content: space-evenly;
}
.auth_login_google {
  width: 100%;
}
/* ---------------Option Page------------------ */
.auth_option_img {
  width: 100%;
}
.auth_option {
  padding: 20px 0;
  width: 80%;
  margin: 0 auto;
  cursor: pointer;
}

/* ------------------------welcome page css------------------------*/
.welcome_cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.auth_img_cont {
  margin-bottom: 4rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
}
.welcome_name {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.register_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.register_btn button {
  padding: 12px 18px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* ------------ thank you page css---------------- */
.thankyou_h {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.smooth-image {
  transition: opacity 1s;
}
.image-visible {
  opacity: 1;
}
.image-hidden {
  opacity: 0;
}

/* ------------ main css half up---------------- */

.auth_back_up {
  width: 100vw;
  /* height: 100vh; */
}
.auth_back_up::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: #000;
  height: 40vh;
  z-index: -1;
  width: 100vw;
}
.auth_back_head {
  font-family: Spartan;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  color: #ffffff;
  text-align: left;
}
.auth_back_p {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  margin: 20px 0 30px 0;
  color: #6d6d6d;
}
.auth_image {
  width: auto;
}
.auth_back {
  font-size: 20px;
  color: #fff;
}
.auth_back i {
  font-size: 20px;
  margin-right: 10px;
}
.shift_img {
  position: relative;
  top: -110px;
}

@media (max-width: 1150px) {
  .auth_root {
    padding: 1rem 5rem;
  }
  .auth_container {
    width: 60%;
  }
}
@media (max-width: 992px) {
  .auth_root {
    padding: 1rem 4rem;
  }
  .auth_logo {
    width: 130px;
  }
  .auth_container {
    width: 60%;
    margin-top: 15vh;
  }
}

@media (max-width: 768px) {
  .auth_root {
    padding: 1rem 3rem;
  }
  .auth_logo {
    width: 110px;
  }
  .auth_container {
    width: 80%;
    margin-top: 13vh;
  }
  .forget_pas_img {
    width: 60px;
  }
  .shift_img {
    top: 0px;
  }
}

@media (max-width: 600px) {
  .auth_logo {
    width: 100px;
  }
  .auth_root {
    padding: 1rem 1rem;
  }
  .auth_container {
    width: 100%;
    margin-top: 8vh;
  }
  .auth_header {
    margin-bottom: 3vh;
  }
  .auth_link_div {
    margin: 10px 0;
    text-align: left;
  }
  .auth_option h5 {
    font-size: 12px;
  }
  .auth_option {
    width: 100%;
  }
  /* .auth_back_part::before{
    height: 50vh;
    width: 100vw;
  } */
}
@media (max-width: 420px) {
  .auth_option_root {
    padding: 20px 0 !important;
  }
  .shift_img {
    margin-top: 20px;
  }
}

/* @media (max-width:1150px){
    
}
@media (max-width:992px){
  
}

@media (max-width:768px){
  
}

@media (max-width:600px){
  
    
} */
