.dashboard_mentor_card {
  min-height: 175px;
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(48, 48, 48, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
}
.side_box{
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 24px;
}
.ele_hover{
  padding: 4px 13px;
  margin-left: -24px;
  /* position: absolute; */
  height: 24px;
  background: rgba(228, 228, 228, 0.5);
  border-radius: 100px;
  z-index: 1;
  transition: 1s ease-in;
  display: none;
}
.assigned-items .MuiAvatar-root
{
  /* position: absolute; */
  z-index: 2;
}
.assigned-items:hover .ele_hover{
  transition: 1s ease-in;
  display: block;
}
