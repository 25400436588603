.notification_panel_root {
  width: 400px;
  z-index: 200 !important;
}
.dashboard_nav .MuiPaper-root {
  background-color: #000 !important;
  color: white;
}
.notification_panel_root_white{
  background-color: #fff !important;
  color: black;

}
.notificationCard__root {
  margin: 5px 5px;
  padding: 5px;
  cursor: pointer;
  border-radius: 0px;
  border-bottom: 1px solid #000;
}
.notificationCard__root p {
  margin: 0;
  color: white;
}
.notification_panel_root_white .notificationCard__root p{
  color: #000 !important;
}
.new_notyf_card{
  background-color: #f2f2f2;
  border-radius: 8px;
  border-bottom: none;
}

@media (max-width: 450px) {
  .notification_panel_root {
    width: 100vw;
  }
}
