.stepperMain {
  padding-top: 30px;
  width: 100%;
  margin: 0 auto;
}
.stepperMain .MuiSvgIcon-root {
  font-size: 1.8rem;
}
.services-main {
  width: 100%;
  height: fit-content;
}
.valuation-newStepper_root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.valuation-newStepper_main span {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.75px;
  background-color: transparent;
}
.color_grey{
  color: #565656;  
}
.color_black{
  color: #000; 

}
.valuation-newStepper_main {
  width: 46%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.valuation_nameOfTheReportDiv {
  background: white;
  padding: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.valuation_nameOfTheReport h4 {
  font-family: Spartan;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 1px;
  color: #121843;
  margin-bottom: 1px;
}

.valuation_nameOfTheReport p {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #565656;
}

.main-tab-content {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
}
.valuationHeading {
  background: #121843;
  padding: 20px 35px;
  color: white;
}
.valuationHeading h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.75px;
  /* text-transform: uppercase; */
}
.valuationHeading p {
  margin-top: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
}
.tabs-content {
  display: flex;
  width: 100%;
  background: #fff;
  flex-wrap: wrap;
}
.tabs-content .MuiTabs-root {
  border-right: 1px solid black !important;
}
.tabs-content .MuiTabs-flexContainer button {
  padding-left: 30px;
  font-family: "Nunito";
}
.tabs-content .MuiTabs-flexContainer {
  align-items: flex-start;
}
.tabpanelcontent {
  width: 80%;
  padding: 12px 25px;
  margin: 0;
  min-height: 400px;
}
.valuation-root .MuiTabs-root {
  width: 20% !important;
}
.valuation-root .MuiTabs-indicator {
  background: linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%);
  border-radius: 0 60% 60% 0;
}

.tabscont {
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  letter-spacing: 1.25px !important;
  text-transform: uppercase !important;
  color: #989898 !important;
}
.tabscont.Mui-selected {
  color: #000 !important;
}
.savenextbtn {
  margin-left: 20px;
  margin-bottom: 20px;
}
.valuation-root.container {
  padding-top: 0px;
  padding-bottom: 40px;
}
.valuation-root .MuiTabs-indicator {
  left: 0 !important;
  width: 5px !important;
}
.valuation-root .Mui-checked {
  color: #4ad7d1 !important;
}
.valuation-root .jss7 {
  background-color: white !important;
  height: auto !important;
  width: auto;
}
.valuation-mainsamplediv figure {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.valuation-mainsamplediv figure img {
  width: 100%;
}
.valation_newDownlaodSample {
  background: #FFFFFF;
  box-shadow: 0px 3px 20px rgb(48 48 48 / 10%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
}
.valation_newDownlaodSample p {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1px;
  color: #989898;
}
.progress_percent{
  position: relative;
  width: 80px;
  height: 80px;
}
.progress_percent svg{
  position: relative;
  width: 80px;
  height: 80px;
}
.progress_percent svg circle{
  width: 100px;
  height: 100px;
  fill: none;
  stroke-width: 8px;
  stroke: #000;
  transform: translate(5px, 5px);
  stroke-dasharray: 440;
  stroke-dashoffset: 300;
}
.progress_percent svg circle:nth-child(1){
  stroke-dashoffset: 0;
  stroke: rgba(18, 24, 67, 0.5);
}
.progress_percent svg circle:nth-child(2){
  stroke-dashoffset: calc(440 - (440 * (40/2)) / 100);
  stroke: #F53939;
}

.progress_persentage_number{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #121843;
}


@media (max-width:950px){
.valuation-root.container {
  padding-top: 50px;
}
}
