.startup_state_calender_root .react-calendar{
    width: 100% !important;
    box-shadow: none !important;
}
.startup_state_calender_root .react-calendar__tile{
    height: 100px !important;
    position: relative;
    padding: 0 !important;
}

.coworking_startup_availableStock{
    position: absolute;
    bottom: 0;
    color: #303030 !important;
    display: flex;
    background: rgba(226, 232, 240, 0.3) !important;
    width: 100%;
}

.coworking_startup_availablespan {
    background-color: #FF6F1E;
    width: 5px;
    /* height: 18px; */
}