.invoicing_allPay_card_root {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 12px;
}

.invoicing_allPay_card_body {
    padding: 16px 16px 24px 16px;
}

.invoicing_allPay_card_footer {
    background: #E2E8F0;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 0 0 12px 12px;
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
}