/** @format */

.valuation_input {
  display: block;
  padding: 20px 10px;
  line-height: 30px;
  height: 38px;
  background-color: #fff;
  border: 1px solid #88a6b6;
  color: #083b56;
  border-radius: 4px;
  font-weight: 300;
  min-width: 94px;
  /* max-width: 100px; */
  text-align: right;
}

.valuation_input:focus {
  outline: none;
  border: thin solid #123769;
}
.valuation_input_label {
  color: #123769 !important;
  padding: 0;
  font-family: "Roboto-Regular";
  line-height: 1;
  letter-spacing: 1px;
  font-weight: 600 !important;
  margin: 15px 0 !important;
  margin-right: 20px;
  font-size: 15px !important;
  /* margin-bottom: 10px !important; */
  font-size: 13px;
}
.ValuationForm .MuiRadio-colorSecondary.Mui-checked {
  color: #123769;
}
