.finance_received_from_td {
    display: flex;
    align-items: center;
    padding: 10px 16px;
}

.finance_received_from_td img {
    border-radius: 12px;
    height: 40px;
    width: 40px;
    margin-right: 10px;
}