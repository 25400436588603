.incubator_dashboard_rootTabBox {
  width: 18%;
}
.incubator_dashboard_tabs2 {
  width: 100%;
}
.incubator_dashboard_tabs {
  width: 100%;
  box-shadow: inset 0px 3px 5px rgb(0 0 0 / 8%);
  height: 91.8vh;
  background: #e2e8f0;
}
.dashboard01_nav2_head2 {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  /* box-shadow: 0px 3px 5px rgb(0 0 0 / 8%); */
}
.incubator_dashboard_tabs2 .MuiTabs-scroller {
  background: #e2e8f0;
}

.dashboard01_tabpanel {
  /* background-color: #F8F9FA;
    width: 82%;
    margin: 60px 0 0;
    overflow-y: scroll; */
  box-shadow: inset 0px 3px 5px rgb(0 0 0 / 8%);
}

.incubator_dashboard_back {
  padding: 20px 28px;
}

.incubator_dash_title {
  padding: 2px 24px 16px 24px;
}
.incubator_dashboard_tabs_main .dashboard01_tabs {
  background: transparent !important;
  width: 100% !important;
}
.event_dashboard_btn_01:hover {
  background: rgba(38, 44, 91, 0.1);
}
.event_dashboard_btn_01_selected {
  background: rgba(38, 44, 91, 0.1);
}

.incubator_incprogram_root {
  height: 100vh;
  overflow: hidden;
}
.incubator_incprogram_main {
  height: 90.95vh;
  display: flex;
  overflow: hidden;
}
.incubator_incprogram_tabs_root {
  width: 18%;
  box-shadow: inset 0px 3px 5px rgb(0 0 0 / 8%);
  height: 100%;
  background: #e2e8f0;
  overflow-y: auto;
}
.incubator_incprogram_tabpanel_root {
  width: 82%;
  background-color: #f8f9fa;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 8%);
  overflow-x: hidden;
  overflow-y: auto;
}
.incubator_incprogram_tab {
  font-family: Poppins;
  font-weight: 500;
  align-items: flex-start;
  text-transform: capitalize !important;
  fill: inherit;
  padding-left: 2vw;
  padding: 8px 15px 8px 2vw !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}
.incubator_incprogram_tab.Mui-selected {
  color: #000000 !important;
}
.dashboard01_tab_svg {
  background-color: white !important;
  padding: 8px;
  /* padding-left: 10px; */
  box-shadow: 0px 3px 5px rgb(0 0 0 / 10%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.incubator_incprogram_tab .dashboard01_tab_svg svg {
  fill: #989898;
}
.incubator_incprogram_tab.Mui-selected .dashboard01_tab_svg svg {
  fill: #000000 !important;
}

.incubator_incprogram_tabs .MuiTabs-indicator {
  display: none;
}
