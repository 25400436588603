.incubation_create_courseFirst {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 200px;
}

.onboarding_cards {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 20px rgb(48 48 48 / 10%);
}

.create_course_root {
  align-items: center;
  background: #F8F9FA;
  display: flex;
  justify-content: flex-start;
  margin: auto;
  min-height: 83vh;
  padding: 0 100px;
  width: 100%;
}

.create_course_form_main_2 {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 40px;
}

.create_module_form_maindiv2 {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 40px;
}

.incubator_learning_mdComp {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 24px 40px;
}

.newgoals {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.box{
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.08);
  border-radius: 10px;
}