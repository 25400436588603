.event-page-box {
  background: #121843;
  border-radius: 10px;
  height: 100%;
}
.event-box-head {
  font-family: Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.event-box-organise {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #e2e8f0;
}
.event-box-image img {
  width: 40px;
  height: 40px;
  border-radius: 60px;
}
.event-box-image {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.75px;
  color: #ffffff;
}
.event-page-detail {
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.event-page-detail-section {
  padding: 8px;
  background: rgba(226, 232, 240, 0.4);
  border-radius: 4px;
  margin-bottom: 10px;
}
.event-page-detail-section img {
  margin-left: 5px;
  margin-right: 15px;
}
.event-page-detail-section span {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  color: #121843;
}

.event-main {
  background: #ffffff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.event-main-head {
  font-family: Spartan;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 1px;
  color: #000000;
}
.event-main-line {
  height: 1px;
  background: #e7e7e7;
}
.event-main-text {
  margin: 20px 0px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #161616;
}
.event-main-sidehead {
  margin: 30px 0 10px 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  color: #262c5b;
}

/* ---------------------MODAL---------------- */

.close-modal-main {
  width: 100%;
  text-align: end;
  margin-top: -20px;
}

.close-modal-main button {
  background: transparent;
  border: none;
  outline: none;
}
.modal-main-text {
  width: 100%;
  margin-top: 10px;
  padding: 0 20px;
}
.modalEvent-date-details {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.modalEvent-date-details div {
  display: flex;
  align-items: center;
  padding: 4px;
}

.modalEvent-date-details div svg {
  width: 20px;
  margin-right: 12px;
}

.modalEvent-date-details div p {
  font-size: 17px;
  font-weight: 600;
  line-height: 0.3;
}
.modalEvent-paybleAmount {
  margin-top: 15px;
}

.modalEvent-paybleAmount p {
  padding-bottom: 10px;
  font-size: 17px;
  font-weight: 500;
}

.modalEvent-paybleAmount h5 {
  font-size: 20px;
  font-weight: 700;
  font-family: "Nunito";
}
