.program-box{
    display: flex;
    background: rgba(226, 232, 240, 0.4);
    padding: 20px 20px; 
    border-radius: 5px;
}
.meetbox-hover{
    /* padding-left: 0px; */
    transition: 0.2s ease;
}
/* .meetbox-hover:hover{
    padding-left: 1px;
} */
.meetbox-hover:hover .meetbox .gradient-div{
    padding-left: 1px;
    background: linear-gradient(169.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%);
}
.main-top-gradient{
    background: #fff;
}
.meetbox-hover:hover .main-top-gradient{
    padding-left: 12px;
}
.meeting_main_gif img {
    width: 120px;
    height: 120px;
}