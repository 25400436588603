.createAssignment_form_divQuestions input[type="text"] {
    border: none;
    border-bottom: 1px solid #98B3D6;
    padding-bottom: 5px;
    outline: none;
}
.createAssignment_form_divAnswer input[type="text"] {
    margin-left: 10px;
    border: none;
    /* border-bottom: 1px solid; */
    border-bottom: 1px solid #98B3D6;
    width: 45%;
    outline: none;
}
.createAssignment_form_divAnswer {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.createAssignment_form_divaddAnswer {
    display: flex;
    align-items: center;
    margin-top: 17px;
}
.createAssignment_form_divMain {
    position: relative;
    padding: 40px;
    border-radius: 10px;
    background: white;
}
.createAssessment_form_divMain {
    position: relative;
    padding: 24px;
    background-color: white;
}
.createAssignment_form_divQuestions {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}
.createAssignment_form_divAnswerKey {
    position: absolute;
    right: 5px;
    top: 22px;
}
.createAssignment_form_divAnswerKey button {
    background: transparent;
    outline: none;
    border: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    letter-spacing: 1px;
    color: #636363;
}
.rotateAnsKey{
    transition: all 0.3s ease-in !important;
    transform: rotate(180deg);
}
.rotateAnsKey2{
    transition: all 0.3s ease-in !important;
    transform: rotate(0deg);
}
.createAssignment_anserkey .answerKeyLabel {
    border-bottom: 1px solid #999999;
    padding: 10px;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.createAssignment_anserkey {
    display: flex;
    align-items: center;
    position: relative;
}
.createAssignment_anserkey:last-child .answerKeyLabel{
    border-bottom: none;
    border-radius: 0 0 10px 10px;
}
.createAssignment_anserkey input[type="radio"] {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
}
.createAssignment_anserkey input[type="radio"]:checked + label{
    transition: all 0.3s ease-in-out;
    background-color: #d0d0d0;
    color: #000;
}
.createAssignment_anserkey_main {
    background: #F8F9FA;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    height: 100%;
}
.createAssignment_anserkey_hide{
    opacity: 0;
    display: none;
}
.createAssignment_anserkey_show{
    display: block;
    animation: showAnsKey 0.3s linear;
}
@keyframes showAnsKey {
    0%{
       opacity: 0;
    }
    25%{
        opacity: 0.25;
    }
    50%{
       opacity: 0.5;
    }
    75%{
        opacity: 0.75;
    }
    100%{
        opacity: 1;
    }
}
.radio_ques {
    width: 12px;
    height: 12px;
    border: 1px solid;
    border-radius: 50%;
    margin-right: 0px;
}
.assesmentEditHead {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
    flex-direction: column;
    background: white;
    padding: 24px;
    border-radius: 10px;
}
.assmentEditHeadmain0 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.assmentEditHeadmain {
    width: 100%;
}
.newModuleassessAddBtn{
    background: #E2E8F0;
    border-radius: 4px;
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    color: #000;
}
.createAssignment_selectAns {
    border: none;
    outline: none;
    /* appearance: none; */
    padding-left: 10px;
}
.createAssignment_selectAns option {
    background: #F8F9FA;
    border: 1px solid #999999;
    border-radius: 0px 0px 10px 10px;
    padding: 10px;
}