.dashboard01_main_box {
  min-height: 100vh;
  position: relative;
}
.dashboard01_tabs {
  background-color: #fff;
  width: 18%;
  border-right: none;
}
.dashboard01_tabs .MuiTab-root {
  color: rgba(103, 103, 103, 1);
  font-family: Poppins;
  font-weight: 500;
  align-items: flex-start;
  text-transform: capitalize;
  fill: inherit;
  padding-left: 2vw;
  padding: 8px 15px 8px 2vw !important;
}
.dashboard01_tab p {
  font-size: 15px;
  font-weight: 600;
}
.dashboard01_tabs .Mui-selected {
  color: #000000 !important;
}
.dashboard01_tabs .dashboard01_tab_svg {
  background-color: white !important;
  padding: 8px;
  /* padding-left: 10px; */
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
}
.dashboard01_tabs .dashboard01_tab_svg svg {
  fill: #989898;
}
.dashboard01_tabs .Mui-selected .dashboard01_tab_svg svg {
  /* fill: #4ad7d1 !important; */
  fill: #000000 !important;
}
.dashboard01_tabs .Mui-selected .dashboard01_tab_svg svg {
  fill: #fff;
}
.dashboard01_tabs .MuiTabs-indicator {
  display: none;
}
.dashboard01_nav_head {
  display: flex;
  align-items: center;
  margin: 20px 2vw 20px;
}
.dashboard01_nav_head img {
  width: 60%;
  height: fit-content;
}
.dashboard01_tab {
  display: flex;
  align-items: center;
}
.dashboard01_tab p {
  margin: 0 10px;
  text-align: start;
}
.dashboard01_tab_p_head {
  padding-left: 2vw;
  font-size: 15px;
}
.dashboard01_tabpanel {
  background-color: #f8f9fa;
  width: 82%;
  /* height: 100%; */
  margin: 60px 0 0;
  overflow-y: scroll;
}
.dashboard01_block {
  margin-top: 15px;
  height: 100%;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 12px;
}
.dashboard01_block {
  overflow-y: scroll;
}
.dashboard01_block::-webkit-scrollbar-track {
  display: none;
}
.dashboard01_block::-webkit-scrollbar-thumb {
  display: none;
}
.dashboard01_tab svg {
  transition: 200ms ease-in-out;
}
.dashboard01_home .Mui-selected {
  color: #000 !important;
}
.dashboard01_home > div .MuiTabs-indicator {
  background: #000;
}
.dashboard01_home .connections_root .MuiBox-root {
  margin: 5px 5px;
}
.dashboard01_home .service-tabbpanel-main .MuiBox-root {
  margin: 0;
}

.dash_option {
  margin-left: 50px;
  text-align: start;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #565656;
  padding: 8px 0;
  transition: 0.3s ease-in;
}
.dashboard01_tab_Valuate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.dashboard01_tab_Valuate button {
  width: 100%;
  padding: 8px;
  background: transparent;
  outline: none;
  border: none;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #565656;
  padding-left: 29%;
  text-align: left;
}
.reportSelected {
  background: rgba(226, 232, 240, 0.5) !important;
}

@media (max-width: 1180px) {
  .dashboard01_tab svg {
    width: 0;
  }
  .dashboard01_tab p {
    margin: 0 5px;
  }
  .dashboard01_tabs .MuiTab-root {
    padding-left: 10px;
  }
  .dashboard01_nav_head {
    margin: 15px 1.5vw 0px;
  }
}
@media (max-width: 992px) {
  .dashboard01_tab {
    align-items: flex-start;
  }
  .dashboard01_tab p {
    text-align: justify;
  }
  .dashboard01_nav_head {
    margin: 15px 1vw 0px;
  }
  .dashboard01_nav_head img {
    width: 80%;
    height: fit-content;
  }
}

@media (max-width: 768px) {
  .dashboard01_tab p {
    display: none;
  }
  .dashboard01_tab svg {
    width: fit-content;
  }
  .dashboard01_tabs {
    width: 10%;
  }
  .dashboard01_tabs .MuiTab-root {
    align-items: center;
    padding-left: 5px;
  }
  .dashboard01_nav_head {
    margin: 10px 0px 20px;
  }
  .dashboard01_nav_head img {
    width: 70%;
    margin: 0 10px;
    height: fit-content;
  }
  .dashboard01_tabpanel {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .dashboard01_tabs .MuiTab-root {
    align-items: flex-start;
    width: 100%;
    padding-left: 5vw;
  }
  .dashboard01_tabs {
    width: 15%;
  }
}
