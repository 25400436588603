.startup_my_cart_card {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 12px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 24px 16px;
}

.startup_my_cart_select {
    display: flex;
    align-items: center;
}

.startup_my_cart_quantity {
    display: flex;
    align-items: center;
}

.startup_my_cart_card_qty_main {
    height: 28px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
}

.startup_my_cart_card_qty_main input {
    border: none;
    outline: none;
    height: 100%;
    padding-left: 10px;
    width: 52px;
    border-radius: 8px;
}

.myCart_card_quan_btn_main {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.myCart_card_quan_btn_main button {
    padding: 0;
    margin: 0;
    width: -moz-fit-content;
    width: fit-content;
    max-height: 14px;
    height: 14px;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.myCart_card_quan_btn_main button svg {
    width: 12px;
    height: 12px;
    color: #989898;
}

.startup_my_cart_money {
    margin-right: 51px;
}

.startup_my_cart_add_asset button {
    background: #123769;
    border: none;
    outline: none;
    color: white;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.startup_mycart_confirm_modal_head {
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 20px;
    position: relative;
}

.startup_mycart_confirm_modal_profile {
    border-bottom: 1px solid #e4e4e4;
}

.mycart_startup_close {
    position: absolute;
    top: -15px;
    right: 0px;
}

.Empty_cart_main{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    color: #989898;
}