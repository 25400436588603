.c_plan_col{
    padding: 16px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
.c_plan_col.active{
    background-color: #787878;
}