.searchbar_analytics {
  background: #e4e4e4;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
}

.searchbar_analytics_text {
  padding: 10px 24px;
  color: #262c5b;
}

.searchbar_analytics_btn {
  background: #ffffff;
  border: 1px solid #262c5b;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.08);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  cursor: pointer;
}

.searchbar_analytics_btntext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #262c5b;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.incubator_analytics_rating_main {
  display: flex;
  align-items: center;
}

.incubator_analytics_rating_star {
  width: 100%;
  height: 12px;
  background: rgba(226, 232, 240, 0.6);
  border-radius: 8px;
  display: flex;
  margin-left: 5px;
}

.incubator_analytics_rating_star_main {
  display: flex;
  align-items: center;
  width: 100%;
}

.incubator_analytics_rating_left {
  width: 45%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.incubator_analytics_rating_star_root {
  width: 55%;
}

.incubator_analytics_rating span {
  width: 20px;
  height: 12px !important;
  font-size: 16px;
  padding-right: 7px;
}

.incubator_analytics_rating {
  color: #000 !important;
}

.incubator_analytics_rating_star_per {
  background: linear-gradient(
    259.04deg,
    #3baca7 4.27%,
    #43c2bc 23.72%,
    #4ad7d1 99.98%
  );
  border-radius: 10px;
  width: 85%;
  height: 100%;
}

.incubator_analytics_star_left5 {
  display: flex;
  align-items: center;
}

p.smallVector {
  width: 12px;
  /* height: 12px; */
  color: #565656;
}

.incubator_analytics_rating_down {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.incubator_analytics_star_left5 p {
  padding-right: 5px;
}

.incubator_analytic_select {
  background: #e4e4e4;
  border-radius: 100px;
  /* padding: 0 40px; */
  outline: none;
  border: none;
}
