.newTask-checkbox {
  padding: 0 !important;
  margin: 2px 0 !important;
}
.newTask-checkbox svg.MuiSvgIcon-fontSizeMedium {
  width: 25px;
  /* padding: 0; */
}
.task-edit_btn {
  padding: 10px 20px;
  background-color: white;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  font-family: Nunito-light;
}
