.dashboardhome01_banner1{
    background:url('../../../Assets//images/Dashboard\ banner\ light\ up.svg') no-repeat;
    padding: 80px 50px;
    background-size:100% auto;
}

.dashboardhome01_banner2{
    background:url('../../../Assets//images/Dashboard\ banner\ dark\ down.svg') no-repeat;
    padding: 40px 30px;
    background-size:100% auto; 
    color: #fff;
}
.mentor_dashboard_add_root {
    background: #FFFFFF;
    border-radius: 12px;
}
.mentor_dashboard_add_text_main {
    position: relative;
    padding: 32px 16px 0 32px;
    background: linear-gradient(259.04deg, rgba(245, 57, 57, 0.75) 4.27%, rgba(255, 111, 30, 0.75) 23.72%, rgba(255, 201, 57, 0.75) 99.98%);
    z-index: 00;
}
.mentor_dashboard_add_text {
    position: absolute;
    width: 18px;
    height: 14px;
    background: #C1D1E6;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 10px;
}
.mentor_dashboard_addlogo {
    position: absolute;
    bottom: 16px;
    right: 16px;
}
.mentor_dashboard_add_highlight {
    background: linear-gradient( 259.04deg, #f53939 4.27%, #ff6f1e 23.72%, #ffc939 99.98% );
}