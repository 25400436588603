:root{
  --transparent: transparent;
  --white:#ffffff;
  --black:#000000;
  --secondBlack:#171717;
  --thirdBlack:#1F1F1F;
  --fourthBlack:#121843;
  --fifthBlack:#161616;
}
body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}
/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
/* font-family: 'Inter', sans-serif; */

@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@700&display=swap');
/* font-family: 'Spartan', sans-serif; */

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
/* font-family: 'Nunito', sans-serif; */
@font-face {
  font-family: "AgusSans";
  src: local("AgusSans"),
    url(./Assets/fonts/AgusSans/AgusSans-Regular.ttf) format("truetype");
}