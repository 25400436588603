.incubator_assessment_table {
    width: 100%;
    border-spacing: 0 20px;
    border-collapse: separate;
}

.incubator_assessment_tbody_tr {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
}

.incubator_assessment_tbody_sub_by {
    padding: 10px 16px;
}

.incubator_assessment_tbody_courseName,
.incubator_assessment_tbody_title,
.incubator_assessment_tbody_sub_on,
.incubator_assessment_tbody_score{
    padding: 10px 0;
}

.incubator_assessment_tbody_view{
    padding: 10px 16px 10px 30px;    
    text-align: -webkit-right;
}

.incubator_assessment_tbody_sub_by {
    display: flex;
    align-items: center;
    /* padding-left: 10px; */
}

.incubator_assessment_tbody_sub_by img {
    border-radius: 50%;
    height: 34px;
    width: 34px;
    margin-right: 10px;
}

.Assessmentresult_main_head {
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 12px;
}

.Assessmentresult_div {
    padding: 12px 20px 16px 20px;
}

.Assessmentresult_left_QBody_root {
    padding: 24px;
}

.Assessmentresult_left_Ques {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    border-bottom: 1px solid #98B3D6;
    padding-bottom: 9px;
}

.Assessmentresult_right_head {
    background: rgba(226, 232, 240, 0.4);
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    padding: 13px 24px;
}

.Assessmentresult_right_head img {
    border-radius: 12px;
    margin-right: 15px;
    height: 45px;
    width: 45px;
}

.Assessmentresult_right_body {
    padding: 18px 24px;
}

.Assessmentresult_right_th,.Assessmentresult_right_td {
    padding-bottom: 24px;
}

.Assessmentresult_div h4 {
    margin-top: 10px;
}
.incubator_assessment_viewBtn{
    border-radius: 100px;
    padding: 4px 20px;
    background-color: transparent;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #F53939 ;
    /* border-image-source: linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%); */


}