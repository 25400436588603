.host-btn{
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 3px 20px rgba(48, 48, 48, 0.1);
    border-radius: 12px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #000000;
    width: -webkit-fill-available;
    height: 100%;
    min-height: 300px;
}
.event-host-btn{
    min-height: 215px;
}