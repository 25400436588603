.realEstate_showReq_profile_tags{
    display: flex;
    align-items: center;
    margin-bottom: 100px;
}

.realEstate_showReq_profile_btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* padding-bottom: 24px; */
}