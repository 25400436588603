.viewReq_head {
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 14px;
}

.viewReq_main_header_cardleft {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 12px;
}

.viewReq_m_header_lcard_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #E2E8F0;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 12px 12px 0px 0px;
    padding: 16px 14px;
}

.viewReq_m_header_lcard_profile img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.viewReq_main_header_cardRight {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 12px;
}

.viewReq_main_header_cardRight_head {
    background: rgba(226, 232, 240, 0.6);
    border-radius: 0px 0px 12px 12px;
    transform: matrix(1, 0, 0, -1, 0, 0);
    height: 64px;
}

.viewReq_head_cardRight_profile_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.viewReq_head_cardRight_profile_img img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    position: absolute;
    top: -50%;
    transform: translateY(-15%);
}

.viewReq_head_cardRight_profile_name {
    padding-top: 21px;
    text-align: center;
}