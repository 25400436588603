figure {
  margin: 0;
  padding: 0;
}

.text-end {
  text-align: end;
}

ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
  padding: 0;
}

p {
  font-family: "Inter";
}

p {
  font-family: "Inter";
}

a {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  letter-spacing: 0.04em;
  line-height: 18px;
  font-style: normal;
  color: inherit;
}

a:hover {
  color: inherit;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: inherit;
}

h1 {
  font-family: "Spartan", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 58px;
  letter-spacing: 0.04em;
  margin: 0;
  padding: 0;
}

h2 {
  font-family: Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
}

.myh2 {
  font-family: "Spartan";
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 64px;
  display: flex;
  align-items: center;
  color: #000000;
}

h3 {
  font-family: "Spartan";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  display: flex;
  align-items: center;
  color: #000000;
  text-transform: uppercase;
}

.caps {
  text-transform: capitalize;
}

h4 {
  font-family: Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.5px;
}

h5 {
  font-family: Spartan;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.5px;
}
.h5style {
  font-family: Spartan;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.5px;
}

h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.75px;
}

.title {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.75px;
}

.subtitle {
  font-family: Spartan;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 1px;
}

.body1 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
}

.body2 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.cta {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  cursor: pointer;
}

.caption {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
}

.overLine {
  font-family: Inter;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 10px;
  line-height: 14px;
}

.ad {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 12px;
  text-transform: uppercase;
  color: #000000;
}
.inner_shadow {
  background: #c4c4c4;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 2px 4px 20px rgba(0, 0, 0, 0.25);
}

.card_shadow {
  background: #c4c4c4;
  box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.1);
}

.slider {
  background: #c4c4c4;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

.dash_icons {
  background: #c4c4c4;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.onboarding_cards {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 3px 20px rgba(48, 48, 48, 0.1);
}

.onboarding_cards2 {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.1);
}
.onboarding_cards3 {
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.08);
  border-radius: 12px;
}

.dahsboard_card {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: #fff;
}

.white_color {
  color: var(--white);
}

.hidden {
  display: none !important;
}

P {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.p_spartan_20 {
  font-family: "Spartan";
  font-size: 20px;
}

.bold {
  font-weight: 600;
}

.p_grey_chip {
  background-color: #e2e8f0;
  padding: 2px 10px;
  margin: 3px;
  width: fit-content;
  border-radius: 20px;
  text-align: center;
}

.tagchip {
  background: rgba(226, 232, 240, 0.6);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 12px;
  width: fit-content;
  padding: 4px 8px;
}

.incubation_chips {
  background: #e2e8f0;
  border-radius: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  color: #565656;
  margin-right: 10px;
  padding: 4px 11px;
  width: fit-content;
}

.p_secBlack12 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #262c5b;
}

.p_secBlack10 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #262c5b;
}

.p_normalBlack10 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #000;
}

.p_secpurple10 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #580167;
}

.p_lightGrey10 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #e2e8f0;
}

.p_LightGrey12 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #636363;
}

.p_LightGrey14 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #565656;
}

.p_black14 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.p_Bold_secBlue14 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1.25px;
  line-height: 20px;
  color: #262c5b;
}

.p_Bold_secBlue18_spartan {
  font-family: Spartan;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 1px;
  color: #262c5b;
}
.blue {
  color: #262c5b;
}
.p_black20 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: 0.5px;
  color: #000000;
}

.p_white20 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.75px;
  color: #ffffff;
}

.no-margin {
  margin: 0;
}

.h-fit {
  height: fit-content !important;
}
.mt-4 {
  margin-top: 4px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mt-6 {
  margin-top: 6px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-12 {
  margin-top: 12px !important;
}
.mt-14 {
  margin-top: 14px !important;
}
.mt-16 {
  margin-top: 16px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-40 {
  margin-top: 40px !important;
}

.mr-4 {
  margin-right: 4px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.mr-6 {
  margin-right: 6px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-12 {
  margin-right: 12px !important;
}
.mr-14 {
  margin-right: 14px !important;
}
.mr-16 {
  margin-right: 16px !important;
}
.mr-24 {
  margin-right: 24px !important;
}
.mr-32 {
  margin-right: 32px !important;
}
.ml-8 {
  margin-left: 8px !important;
}
.ml-6 {
  margin-left: 6px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-12 {
  margin-left: 12px !important;
}
.ml-16 {
  margin-left: 16px !important;
}
.ml-24 {
  margin-left: 24px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.ml-60 {
  margin-left: 40px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mb-6 {
  margin-bottom: 6px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-12 {
  margin-bottom: 12px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.p-24 {
  padding: 24px !important;
}
.pt-8 {
  padding-top: 8px !important;
}
.pt-6 {
  padding-top: 6px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-12 {
  padding-top: 12px !important;
}
.pt-16 {
  padding-top: 16px !important;
}
.pt-24 {
  padding-top: 24px !important;
}
.pt-40 {
  padding-top: 40px !important;
}

.pr-8 {
  padding-right: 8px !important;
}
.pr-6 {
  padding-right: 6px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pr-16 {
  padding-right: 16px !important;
}
.pr-24 {
  padding-right: 24px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pl-8 {
  padding-left: 8px !important;
}
.pl-6 {
  padding-left: 6px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-12 {
  padding-left: 12px !important;
}
.pl-16 {
  padding-left: 16px !important;
}
.pl-24 {
  padding-left: 24px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.pb-8 {
  padding-bottom: 8px !important;
}
.pb-6 {
  padding-bottom: 6px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-12 {
  padding-bottom: 12px !important;
}
.pb-16 {
  padding-bottom: 16px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-24 {
  padding-bottom: 24px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.p-10 {
  padding: 10px;
}
.p-16 {
  padding: 16px !important;
}
.p-4 {
  padding: 4px;
}
.p-40 {
  padding: 40px !important;
}
.p-8 {
  padding: 8px;
}
.w-fit {
  width: fit-content !important;
}

.grey {
  color: #757575;
}

.font12 {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.04em;
  margin: 0;
  padding: 0;
}

.p_spartan_20 {
  font-family: "Spartan";
  font-size: 17px;
}

.bold {
  font-weight: 600;
}

.spartan {
  font-family: "Spartan";
}

.p_grey_chip {
  background-color: #e2e8f0;
  padding: 2px 10px;
  margin: 3px;
  width: max-content;
  border-radius: 20px;
  text-align: center;
}

.no-margin {
  margin: 0;
}

.grey {
  color: #757575;
}

.grey2 {
  color: rgba(101, 103, 109, 1);
}

.purple {
  color: #580167;
}

.orange {
  color: #ff6f1e;
}

.font10 {
  font-size: 10px;
}

.font12 {
  font-size: 12px !important;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}

.font25 {
  font-size: 25px;
}

.font35 {
  font-size: 35px;
}

button {
  font-family: "Inter";
}

.back_CTA {
  cursor: pointer;
}

.w_fit {
  width: fit-content !important;
}

.text-center {
  text-align: center;
}

.r_a {
  align-items: center;
}

.r_b {
  justify-content: space-between;
}

.r_sa {
  justify-content: space-around;
}

.h_100 {
  height: 100%;
}

/* .MuiAvatar-root {
  border-radius: 20% !important;
} */

.white_bg {
  background: #fff !important;
}

.pink_bg {
  background: rgba(88, 1, 103, 0.1);
}

.light_bg {
  background: rgba(255, 111, 30, 0.1);
}

.grey_bg {
  background: rgba(38, 44, 91, 0.1);
}

.Light_grey_bg {
  background: rgba(226, 232, 240, 0.4);
}

.color_dark_blue {
  color: #262c5b !important;
}

.color_dark_grey {
  color: #636363 !important;
}
.color_dark_grey2 {
  color: #565656 !important;
}

.color_light_grey {
  color: #989898 !important;
}

.color_dark_black {
  color: #000 !important;
}
.color_light_black {
  color: #303030 !important;
}
.color_slaty_dark {
  color: #370041 !important;
}
.color_dark_black2 {
  color: #121843 !important;
}
.color_dark_black_sec {
  color: rgba(0, 0, 0, 0.75) !important;
}

.alert_CTA {
  border: 1px solid #f53939;
  border-radius: 6px;
  color: #ff6f1e;
  padding: 5px 10px;
}

.shadow_CTA {
  background: #ffffff;
  border-radius: 8px;
  -webkit-box-shadow: 1px 1px 8px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 8px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 1px 8px -2px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  border: none;
  padding: 8px 12px;
  font-weight: 600;
}

button {
  font-family: "Inter";
}

.profile_img {
  border-radius: 6px;
}

.point {
  cursor: pointer;
}

/* width code */
.w_100 {
  width: 100% !important;
}

.w_90 {
  width: 90% !important;
}

.w_80 {
  width: 80% !important;
}

.w_75 {
  width: 75% !important;
}

.w_70 {
  width: 70% !important;
}

.w_60 {
  width: 60% !important;
}

.w_50 {
  width: 50% !important;
}

.w_49 {
  width: 49% !important;
}

.w_48 {
  width: 48% !important;
}

/* cta's */
.primaryFilled_inactiveCta {
  background: linear-gradient(
    259.04deg,
    #f53939 4.27%,
    #ff6f1e 23.72%,
    #ffc939 99.98%
  );
  border-radius: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #989898;
  transition: background 0.5s linear;
  -webkit-transition: background 0.5s linear;
  -o-transition: background 0.5s linear;
  -moz-transition: background 0.5s linear;
  border: none;
  outline: none;
  padding: 11px 26px;
}

.primaryFilled_activeCta {
  background: #000000;
  border-radius: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  border: none;
  outline: none;
  padding: 11px 26px;
}
.no-border input {
  border: none !important;
}

.primaryFilled_activeCta:hover {
  transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  background: linear-gradient(
    100.96deg,
    #f53939 4.27%,
    #ff6f1e 23.72%,
    #ffc939 99.98%
  );
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
  /* transform: rotate(20deg); */
}

.secondaryOutlined_activeCta {
  border: 2px solid #262c5b;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  padding: 11px 26px;
  color: #000000;
  background: transparent;
}
.secondaryFilled_activeCta {
  border: 2px solid #262c5b;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  padding: 11px 26px;
  color: white;
  background: #262c5b;
}
.secondaryOutlined_inactiveCta {
  border: 2px solid #262c5b;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  padding: 8px 16px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  background-color: transparent;
  color: #989898;
}
.secondaryfilled_inactiveCta {
  border: 2px solid #262c5b;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  padding: 8px 16px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  background-color: #262c5b;
  color: #989898;
}

.primaryOutlined_activeCta {
  border: 3px solid #000000;
  border-radius: 30px;
  padding: 8px 34px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #000000;
  background-color: transparent;
}
.primaryOutlined_inactiveCta {
  border: 2px solid #f53939;
  border-radius: 30px;
  padding: 8px 34px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #989898;
  background-color: transparent;
}

.transparent_cta {
  background: transparent;
  outline: none;
  border: none;
}

.btncta {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #989898;
}

.small_span {
  font-size: 8px;
  color: #565656 !important;
}

@media screen and (max-width: 1100px) {
  h1 {
    font-size: 45px;
  }

  p {
    font-size: 18px;
  }
}

@media screen and (max-width: 990px) {
  h1 {
    font-size: 35px;
    line-height: 40px;
  }

  h2 {
    font-size: 27px;
    line-height: 40px;
  }

  p {
    font-size: 17px;
    line-height: 25px;
  }
}

.gradient_btn_main {
  background: #000000;
  padding: 2px;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  display: flex;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
}
.gradient_btn_dark {
  background: rgb(0, 0, 0);
  padding: 8px 16px !important;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  position: cursor;
}
.gradient_btn_light {
  background: #ffffff;
  padding: 8px 16px !important;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  position: cursor;
}
.backtotop {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 100;
  /* transition: all 0.5s ease; */
}
.color_98 {
  color: #989898 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("./../../Assets/images/calendar_icon.svg") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("./../../Assets/images/clock_icon.svg") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

@media screen and (max-width: 426px) {
  .container {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
}
