.event_card_head_root {
    background: white;
    border-radius: 12px;
}
.event_card_head_fDetails {
    height: 137px;
    filter: drop-shadow(0px 3px 20px rgba(48, 48, 48, 0.1));
    border-radius: 12px 12px 0px 0px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
}
.event_card_head__fDetail {
    display: flex;
    /* padding-top: 10px; */
    flex-wrap: wrap;
    align-items: center;
}
.event_card_head__fDetail p {
    background: #E2E8F0;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 0px 14px;
    font-size: 14px;
    margin-right: 10px;
}
.event_card_Card_mainInfo {
    position: absolute;
    bottom: auto;
    top: 50%;
    background: #FFFFFF;
    box-shadow: 0px 3px 20px rgb(48 48 48 / 10%);
    border-radius: 12px;
    padding: 8px;
    left: 19px;
    width: 65%;
}
.event__card_participatend_root {
    margin-top: 65px;
    display: flex;
    padding-bottom: 15px;
    flex-direction: column;
}
.event__card_participatend_root .MuiAvatarGroup-avatar {
    font-size: 12px;
    width: 35px;
    height: 35px;
}