.viewAsset_card_quan_input_main {
    /* display: flex; */
    height: 48px;
    position: relative;
    /* width: 82px; */
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
}

.viewAsset_card_quan_btn_main {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
}

.viewAsset_card_quan_btn_main button {
    padding: 0;
    margin: 0;
    width: fit-content;
    max-height: 24px;
    background: transparent;
    border: none;
}

.viewAsset_card_quan_btn_main button svg {
    width: 16px;
    height: 16px;
    color: #989898;
}

.viewAsset_card_quantity input {
    border: none;
    outline: none;
    height: 100%;
    padding-left: 20px;
    padding-top: 8px;
    width: 82px;
    border-radius: 8px;
}

.viewAsset_other_products_root {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 12px;
}

.viewAsset_other_products_head {
    background: rgba(226, 232, 240, 0.6);
    border-radius: 12px 12px 0px 0px;
    padding: 16px;
}

.viewAsset_other_products {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.viewAsset_other_prod_main img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.startup_state_calender_head {
    background: rgba(226, 232, 240, 0.6);
    border-radius: 12px 12px 0 0;
}

.startup_calender_head_date {
    display: flex;
    justify-content: center;
    align-items: center;
}

.startup_state_calender_root {
    background: white;
    border-radius: 12px;
}

.startup_state_calender_main {
    padding: 16px 25px;
}

.startup_state_calender_footwer {
    background: white;
    border-radius: 0 0 12px 12px;
}

.startup_calender_head_date input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 170px;
    padding: 10px 16px;
}