.incbator_invoice_pdf_root {
    width: 792px;
    background: white;
    margin: auto;
}

.incbator_invoice_pdf_head {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 4px solid #FF6F1E;
}

.pdf-email-bg {
    width: 7px;
    height: 7px;
    margin-right: 4px;
    background-color: #FF6F1E;
}

.incbator_invoice_pdf_table table thead tr td {
    padding: 8px;
}

.incbator_invoice_pdf_table table tbody tr {
    border-bottom: 1px solid #98B3D6;
}

.incbator_invoice_pdf_table table tbody tr td:nth-child(odd) {
    background-color: #EDEDED;
    padding: 16px;
}

.incbator_invoice_pdf_table table tbody tr td:nth-child(even) {
    padding: 16px;
    background-color: #f9f9f9;
}

.incbator_invoice_pdf_pay_table table tr td:first-child {
    padding-right: 40px;
}

.incbator_invoice_pdf_pay_subtotal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid #98B3D6;
}

.incbator_invoice_pdf_pay_discount {
    border-bottom: 3px solid #FF6F1E !important;
}

.incbator_invoice_pdf_pay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0;
}

.incbator_invoice_pdf_footer {
    margin-top: 75px;
    border-top: 1px solid #98B3D6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0 25px 0;
}