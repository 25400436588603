.announcement-box{
    /* display: flex; */
    padding: 10px 20px; 
    background: rgba(248, 249, 250, 0.35);
    border: 0.5px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 8px;
}
.announcement-box::after{
    content:"";
    position: absolute;
    top: 0;
    left: 15px;
    height: 100%;
    width: 8px;
    background: rgba(88, 1, 103, 0.1);
    border-radius: 10px 0px 0px 10px;
}