.coming_soon_root {
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.coworking_incubator_percent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.coworking_incubator_percent .CircularProgressbar {
    width: 75% !important;
}
.coworking_incubator_percent .CircularProgressbar .CircularProgressbar-trail {
    stroke: #999999 !important;
}

.rental_per .CircularProgressbar-path{
    stroke: #FBCF33 !important;
}
.seater_per .CircularProgressbar-path{
    stroke:  #FF6F1E !important;
}