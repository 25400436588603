.letstart-head-back {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.letstartp {
    font-family: 'Nunito-light';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
}
.letstarth6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
    margin-bottom: 10px !important;
}
.letstarth5 {
    font-family: 'Nunito-light';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    margin-bottom: 10px !important;
}

.letStartcard-main {
    background: white;
    border-radius: 12px;
    padding: 20px;
}
.letStart-card-imgMain {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0 17px 0;
}
.letstartcard-moreinfo a {
    color: #36bbb5 !important;
}
.letstartbtn {
    width: 100%;
    margin-top: 2.5em;
    margin-bottom: 1.3em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.letstartbtn button {
    background: linear-gradient(90deg, #68C1D2 0%, #2BD2B6 99.99%);
    border-radius: 8px;
    border: none;
    outline: none;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    padding: 13px 33px;
    font-weight: 700;
}

.report_title-main {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.report_title-main label {
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
}

.report_title-main input {
    border-radius: 6px;
    border: 1px solid #757575;
    width: 40%;
    margin-top: 6px;
    padding: 6px;
}

.letstart-backBtnMain button {
    background: transparent;
    outline: none;
    border: none;
    margin-left: -10px;
    margin-bottom: 10px;
}