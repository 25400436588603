.newValuation-btn {
    outline: none;
    background: linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%);
    border-radius: 8px;
    border: none;
    color: black;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    padding: 12px 33px;
    text-transform: uppercase;
}

.NewValuation-root {
    width: 100%;
}

.NewValuation-root p,
.NewValuation-root h5,
.NewValuation-root h6 {
    margin: 0;
}

.newValuationhead {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.newValuationhead h6 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #000000;
}

.valuation-mainDefdiv {
    background: white;
    padding: 24px;
    border-radius: 10px;
    height: 100%;
    box-shadow: 0px 3px 20px rgba(48, 48, 48, 0.1);
}
.Height_100{
    height: 100%;
}
.report_impactful_root1 {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.report_impactful_root1 h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.75px;
    margin-bottom: 10px;
}
.addInforMation_root img {
    width: 100%;
}
.report_impactful_root1 button {
    border: 1px solid #ff8525;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin-top: 20px;
    letter-spacing: 1px;
    width: fit-content;
    padding: 13px 14px;
}
.report_impactful_root1 p {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
}

.valuation-mainQues h5 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #000000;
}

.valuation-mainAns {
    margin-top: 10px;
    margin-bottom: 10px;
}

.valuation-mainAnsp {
    font-family: 'Nunito-light';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
}

.valuation-mainDefVdo iframe {
    width: 100%;
    height: 100%;
}

.valuation-Ongoing-main {
    margin-top: 20px;
    padding: 20px;
    border-radius: 12px;
}

.valuation-ongoing-mainHead p {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #000000;
}

.valuation-ongoingMain {
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 10px;
    height: 100%;
}
.valuation_newgenerateReport {
    width: 100%;
    height: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.valuation_newgenerateReport p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    margin-top: 15px;
}
.valuation_newgenerateReport img, .valuation_newgenerateReport p{
    cursor: pointer;
}
.newValuation_progress_main {
    width: 100%;
    border-radius: 0px 0px 20px 20px;
    position: relative;
    height: 35px;
    border-top: 1px solid #d3ddeb;
}
.newValuation_progress-shown{
    background: linear-gradient(259.04deg, #98B3D6 4.27%, #E2E8F0 99.98%);
    /* border-radius: 0px 0px 20px 20px; */
    height: 35px;
}

.newValuation_progress_span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1px;
}

.Valuation_report_title {
    font-family: Spartan;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1px;
    color: #000000;
}

.Valuation_report_sub_title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #565656;
}
.valuation_Status_root {
    display: flex;
    justify-content: space-between;
    /* margin-top: 25px; */
}
.valuation_status p {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #989898;
}
.valuation_status p:nth-child(2) {
    font-size: 12px;
    margin-top: 3px;
    color: #000;
}

.valuation-ongoing-downloadbtn button {
    background: linear-gradient(90deg, #68C1D2 0%, #2BD2B6 99.99%);
    border-radius: 4px;
    color: white;
    border: none;
    outline: none;
    padding: 7px 16px;
    font-size: 12px;
}

.valuation-ongoing-downloadbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;
}

.academia_project_More2 button {
    color: #000;
}

.academia_project_More2 button svg {
    width: 1.2em;
    height: 1.2em;
}

.valuation-ongoing-downloadbtn a {
    border: 2px solid #4AD7D0;
    padding: 5px 8px;
    border-radius: 4px;
    font-size: 12px;
    color: #4AD7D1;
}
.valuation-OngoingTitleDiv {
    height: 143px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0px 0px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
    border: 1px solid #E4E4E4;
}

.smallP_lightGrey{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #989898;
}
.smallP_Black{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #000;
}
.newValuation_statuMain {
    display: flex;
    /* justify-content: space-between; */
    width: 70%;
}
.newValuation_status {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}
.newValution_DivQ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 75%;
}

.academia_collab_btn-main a {
    font-size: 13px;
    color: #656565;
    font-weight: 700;
}

/* progress css */
.valuation-progress-span {
    width: 49%;
    height: 3.5px;
    border-radius: 3px;
}

.valuation-progress-main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.valuation-progress-main svg {
    width: 15px;
    fill: #4AD7D1;
    color: black;
}

.valuation-progress-active {
    background: #4AD7D1;
}

.valuation-progress-deactive {
    background: #cacaca;
}

.valuation-progress-no {
    width: 13px;
    height: 13px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #757575;
    color: #757575;
    border-radius: 50%;
    padding: 5px;
    text-align: center;
}

.valuation-progess-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
}