.incubator_dashboard_rootTabBox {
  width: 18%;
}
.incubator_dashboard_tabs2 {
  width: 100%;
}
.incubator_dashboard_tabs {
  width: 100%;
  box-shadow: inset 0px 3px 5px rgb(0 0 0 / 8%);
  height: 91.8vh;
  background: #e2e8f0;
  overflow-y: auto;
}
.dashboard01_nav2_head2 {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  /* box-shadow: 0px 3px 5px rgb(0 0 0 / 8%); */
}
.incubator_dashboard_tabs2 .MuiTabs-scroller {
  background: #e2e8f0;
}

.dashboard01_tabpanel {
  /* background-color: #F8F9FA;
    width: 82%;
    margin: 60px 0 0;
    overflow-y: scroll; */
  box-shadow: inset 0px 3px 5px rgb(0 0 0 / 8%);
}

.incubator_dashboard_back {
  padding: 20px 28px;
}

.incubator_dash_title {
  padding: 2px 24px 16px 24px;
}
.incubator_dashboard_tabs_main .dashboard01_tabs {
  background: transparent !important;
  width: 100% !important;
}
