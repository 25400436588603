.userCourses_tabs_root {
    width: 30vw;
    height: 100vh;
}
.userCourses_tabs_main {
    width: 100%;
}
.userCouses_dashRoot01{
    width: 100% !important;
}
.userCourse_head_main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 64px;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
    box-shadow: inset 0px 2px 3px rgb(0 0 0 / 8%);
}
.userCourse_head_back {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.userCourse_head_prgoress_root {
    width: 65%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #e4e4e4;
}
.userCourse_head_prgoress_main {
    width: 86%;
    height: 12px;
    background: #E4E4E4;
    border-radius: 6px;
    margin-top: 4px;
    position: relative;
}
.userCourse_head_prgoress {
    position: absolute;
    width: 25%;
    left: 0;
    top: 0;
    background: linear-gradient(259.04deg, #98B3D6 4.27%, #E2E8F0 99.98%);
    height: 100%;
    border-radius: 10px;
}
.user_course_nav_head{
    margin-bottom: 17px !important;
}
.userCourses_tabs {
    /* padding: 10px 20px; */
    border-bottom: 1px solid #e4e4e4;
}
.userCourses_tabs_sessions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 47px;
    padding: 11px;
    transition: all 0.2s linear;
}
.userCourseSession_title_ span svg {
    fill: #989898;
    width: 18px;
}
.userCourses_tabs_sessions:hover {
    cursor: pointer;
    background: #EFF2F6;
}
.userCourses_tabPanel_content_root {
    width: 100%;
    background: #f8f9fa;
    margin-top: 60px;
}
.userCourses_tabPanel_content_head {
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px 2px 3px rgb(0 0 0 / 8%);
    padding: 8px 32px;
}
.userCourses_tabPanel_content_head_detail>div {
    padding: 10px;
}
.userCourses_tabPanel_content_main {
    width: 100%;
    padding: 35px;
    height: 83vh;
    overflow-y: auto;
}
.userCourses_tabPanel_contents {
    width: 80%;
}
.userCourses_tabpanel_downContent_root {
    margin-top: 28px;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
    border-radius: 8px;
    padding: 16px 22px;
}
.userCourses_tabpanel_downContent_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.usercourse_tabPanel_downSchedule span svg {
    width: 22px;
}
.userCourses_tabPanel_content_head_detail {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.userCourses_tabpanel_downTabs_tab:first-child{
    border-right: 1px solid #000;
}
.userCourses_tabpanel_downTabs_tab {
    color: #989898 !important;
    text-shadow: 0px 4px 20px rgb(48 48 48 / 8%) !important;
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 1px !important;
}
.userCourses_tabpanel_downTabs_tab.Mui-selected{
    color: #000 !important;
}
.userCourses_tabpanel_downTabs_tabs .MuiTabs-indicator{
    display: none;
}

/* assessment.js */
.userCourses_tabPanel_assessment_main {
    width: 76%;
    background: white;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 10px;
    padding: 20px;
}

.userCourses_tabPanel_assess_ques_main hr {
    margin: 6px 0;
}
.userCourses_tabPanel_assess_Ans {
    display: flex;
    margin: 6px 0;
    align-items: center;
}
.userCourses_tabPanel_assess_Ans p {
    color: #303030;
    margin-left: 10px;
}
.userCourses_assessment_qAroot{
    margin-bottom: 40px;
}
.userCourses_tabPanel_assesmentCorrectmain {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 10px;
    padding: 15px;
}
.program_deleteIcon{
    height: 40px;
    width: 40px;
    /* border: 1px solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
}
.program_deleteIcon:hover{
    transition: all 0.3s ease-in-out;
    box-shadow: 2px -3px 4px 3px rgb(48 48 48 / 8%), 1px 2px 4px 3px rgb(48 48 48 / 8%);
}
/* radio css */
.userCourse_radio_main {
    position: relative;
}
.userCourse_radio_main input[type="radio"] {
    width: 0;
    height: 0;
    position: absolute;
}
.userCourse_radio {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #FF6F1E;
    margin: 0;
    /* background: linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%); */
    /* position: absolute; */
}
.userCourse_rCheckd{
    background: linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%);

}
.userCourse_rNotCheckd{
    background: transparent;

}
.userCourse_radio_input:checked + label{
    background: linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%);
}
.userCourses_assignment_remark {
    width: 90%;
    border: none;
    border-bottom: 1px solid #98B3D6;
    background: transparent;
    padding: 0px 5px 10px 3px;
    height: fit-content;
}

.userCourses_tabs_lock_session {
    cursor: not-allowed !important;
}

.userCourses_tabs_lock_session p {
    color: rgba(0, 0, 0, 0.5);
}

.userCourses_tabs_lock_session span svg {
    fill: rgba(152, 152, 152, 0.5);
}
.reactPlayer_userProgram video{
    border-radius: 8px;
}