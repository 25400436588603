.incubation_create_courseFirst {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 200px;
}

.onboarding_cards {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 20px rgb(48 48 48 / 10%);
}

.create_course_root {
  align-items: center;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  margin: auto;
  min-height: 83vh;
  padding: 0 100px;
  width: 100%;
}
.create_course_form_main {
  margin-top: 26px;
}
.create_course_form_maindiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
}

.create_course_form_maindiv input,
.create_course_form_maindiv textarea {
  width: 80%;
  border: none;
  border-bottom: 1px solid #98b3d6;
  padding-bottom: 6px;
  padding-left: 3px;
  outline: none;
}

.create_course_form_maindiv label {
  margin-bottom: 6px;
}
.create_course_main {
  padding: 5px 40px;
}
.create_course_form_maindivBtn {
  margin-top: 30px;
}
.newgoals {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
