.auth_back_loginform-para{
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    color: #6D6D6D;
    margin: 20px 0px;

}
.auth_back_loginform-para span{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.75px;
    color: #263238;
}