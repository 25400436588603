li.recharts-legend-item span {
    font-size: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    /* display: flex; */
    /* align-items: center; */
}
li.recharts-legend-item svg {
    /* width: 12px; */
    /* height: 14px; */
    /* border-radius: 50%; */
    margin-right: 8px !important;
}