.incubator_assignment_card_root {
    padding: 10px;
    margin-bottom: 8px;
}

.incubator_assignment_card_main {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
}

.incubator_assignment_card {
    padding: 15px;
}

.incubator_assignment_card_headLeft img {
    margin-right: 7px;
    border-radius: 50%;
    height: 35px;
    width: 36px;
}

.incubator_assignment_card_headLeft p {
    margin-top: 3px;
}

.incubator_assignment_card_headRight {
    display: flex;
    justify-content: flex-end;
}

.incubator_assignment_card_mainInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.incubator_assignment_notcheck svg {
    width: 16px;
    height: 16px;
    fill: #989898;
    margin-right: 6px;
}

.incubator_assignment_card_mainInfo p {
    margin-bottom: 8px;
}

.incubator_assignment_card_checkInfo {
    padding-right: 12px;
}

.incubator_assignment_card_footer {
    background: rgba(88, 1, 103, 0.1);
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.incubator_assignment_modal_main {
    padding: 35px;
}

.incubator_assignment_modal_input {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}

.incubator_assignment_modal_input label {
    margin-bottom: 2px;
}

.incubator_assignment_modal_input input {
    border: none;
    border-bottom: 1px solid #98B3D6;
    outline: none;
}

.incubator_assignment_modal_submit {
    margin-top: 40px;
}

.incubator_assignment_modal_submit button {
    width: 40%;
}
.incubator_assignment_modal_root {
    width: 600px;
    outline: none;
}

.incubator_assignment_modal_head button {
    background: transparent;
    outline: none;
    border: none;
    position: absolute;
    right: 27px;
    top: 27px;
}

.incubator_assignment_modal_head button svg{
    fill: #262C5B;
}