.create_course_root {
    width: 100%;
    min-height: 83vh;
    margin: auto;
    background: #F8F9FA;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 100px;
}
.create_course_form_main {
    margin-top: 26px;
}
.create_assignment_form_main {
    margin-top: 26px;
    background: white;
    padding: 40px;
    border-radius: 10px;
}
.create_course_form_maindiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;
}
.create_course_form_maindiv:last-child {
    margin: 0;
}
form.create_course_form_root {
    /* width: fit-content; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.create_course_form_maindiv input, .create_course_form_maindiv textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid #98B3D6;
    padding-bottom: 6px;
    padding-left: 3px;
    outline: none;
}

.create_course_form_maindiv label {
    margin-bottom: 6px;
}
.create_course_main {
    padding: 5px 40px;
}
.create_course_form_maindivBtn {
    margin-top: 30px;
}

.tagchips{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    flex-wrap: wrap;
    width: 83%;
}
.tagchips p {
    margin-bottom: 5px;
}
.tagchips p button {
    background: transparent;
    outline: none;
    border: none;
    color: black;
    font-weight: bold;
    font-size: 11px;
    cursor: pointer;
    margin-left: 5px;
    transition: all 0.2s ease;
}
.tagchips p button:hover {
    color: red;
}
.create_newSession_uploadVideoDi {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 10%);
    border-radius: 8px;
    padding: 0px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
}
.create_newSession_uploadVideoDi input {
    opacity: 0;
    height: 0;
    width: 0;
}
.create_course_form_maindiv p {
    font-family: Spartan;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 1px;
    width: fit-content;
}

/* session */
.session-video {
    position: relative;
    width: -moz-fit-content;
    max-width: 250px;
    width: fit-content;
}
.session-video button {
    position: absolute;
    right: 0;
    z-index: 9099;
    background: transparent;
    outline: none;
    border: none;
    color: white;
}
.module_main_div {
    width: 100%;
}
.modulecomp_icons svg {
    fill: #98B3D6;
}
.modulecomp_icons {
    padding-left: 20px;
}
.modulecomp_Sessicons {
    padding-left: 20px;
}
.allbtns_module_main {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
}
.allbtns_module_main button {
    border: none;
    outline: none;
    background: transparent;
    display: flex;
    align-items: center;
    border-right: 1px solid #580167;
    padding: 0 12px;
}
.allbtns_module_main button:first-child {
    padding-left: 0;
}
.allbtns_module_main button:last-child {
    border: 0;
}
.allbtns_module_main button svg{
    fill: #262C5B;
}
.newModuleBtn.color_dark_blue {
    width: 100%;
    border: 1px solid #262C5B;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border-radius: 4px;
}
.program_upload_imglater {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 10%);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.newModuleBtn {
    background: #E2E8F0;
    border-radius: 4px;
    border: none;
    outline: none;
    height: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.session_inc_main {
    padding: 12px 0;
    border-bottom: 1px solid #e4e4e4;
    position: relative;
}
.session_inc_main:hover .session_left_comp{
    transition: 0.3s all linear;
    opacity: 1;
}
.session_left_comp {
    position: absolute;
    width: 12px;
    height: 22px;
    background: #e4e4e4;
    border-radius: 4px;
    left: -19px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    opacity: 0;
}
.session_left_comp>div {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.session_left_comp>div {
    width: 100%;
    display: flex;
    justify-content: space-around;
}