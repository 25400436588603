.incubator_analytics_courses_root {
    background: white;
}

.incubator_analytics_courses_head {
    padding: 20px 24px;
    border-bottom: 1px solid #E4E4E4;
}

.incubator_analytics_coursename {
    padding: 0 20px;
}

.incubator_analytics_courseModule {
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    transition: 0.5s ease-in-out;
}
.incubator_analytics_courseModule:hover {
    background-color: #F0F3F7;
}

.showAnalyticsBg{
    background-color: #F0F3F7;
}

.incubator_analytics_courseModules {
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 8px;
}

.incubator_analytics_rating_info_main {
    padding: 12px 24px;
    background: white;
}

.incubator_analytics_rating_table {
    width: 100%;
}

.incubator_analytics_rating_thead_tr th:first-child{
    width: 49%;
    text-align: start;
}
.incubator_analytics_rating_tbody_tr td:first-child {
    width: 100%;
    text-align: start;
}

.incubator_analytics_rating_thead_tr th,
.incubator_analytics_rating_tbody_tr td {
    padding-bottom: 8px;
    text-align: center;
}

.incubator_analytics_rating_thead {
    border-bottom: 1px solid #E4E4E4;
    /* padding-bottom: 10px; */
}

.incubator_analytics_rating_tbody_tr {
    border-bottom: 1px solid #e4e4e4;
}

.incubator_analytics_rating_tbody_tr:last-child {
    border-bottom: none;
}

.incubator_analytics_rating_tbody_tr td {
    padding: 24px;
}

.incubator_analytics_rating_tbody_tr td:first-child {
    padding: 24px 0;
}

.incubator_analytics_rating_tbody_td_img {
    display: flex;
    align-items: center;
}

.incubator_analytics_rating_tbody_td_img img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 50%;
}

.incubator_analytics_rating_maininfo {
    background: white;
    margin-bottom: 16px;
    padding: 16px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.incubator_analytics_rating_proQuality_main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.incubator_analytics_rating_progress_main {
    width: 75%;
    background: rgba(226, 232, 240, 0.6);
    border-radius: 100px;
    height: 18px;
    display: flex;
    align-items: center;
}

.incubator_analytics_rating_pr {
    height: 100%;
    background: linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%);
    border-radius: 100px;
}

.incubator_analytics_rating_proQuality_main p {
    padding-top: 2px;
}

.incubator_analytics_rating_overall {
    width: 110px;
    height: 110px;
}

.incubator_aly_rating_overall_main {
    background: rgba(226, 232, 240, 0.6);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.incubator_analytics_rating_progress {
    width: 80%;
}

.incubator_aly_rating__ {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 0px; */
}

.incubator_aly_rating_read{
    font-size: 11px !important;
    color: #F53939 !important;
}

.incubator_analytic_course_star_progress {
    width: 81%;
    background: rgba(226, 232, 240, 0.6);
    border-radius: 100px;
    height: 16px;
    display: flex;
    align-items: center;
}

.incubator_analytic_course_star_root {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px;
}

.incubator_analytic_course_star_head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 42px;
    margin-bottom: 42px;
}

.incubator_analytic_course_stars>span {
    margin-right: 20px;
}
.incubator_analytic_course_stars>span:last-child {
    margin-right: 0;
}

.incubator_analytic_course_start_assignmentRoot {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 16px 24px;
}

.incubator_analytic_course_overview_body {
    height: 100%;
    width: 100%;
    max-height: 290px;
}

.incubator_analytic_course_overview_root {
    height: 100%;
    width: 100%;
    background: white;
    border-radius: 10px;
    padding: 24px;
}

.incubator_analytic_course_overview_body .recharts-layer.recharts-cartesian-axis-tick {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #565656;
}

.analy-profile{
    background-color: #FFFFFF;
    padding: 18px;
}