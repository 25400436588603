.messages_recent_block{
    height: 80vh;
    overflow-y: scroll;
}
.messages_recent_block_item{
    padding: 10px ;
}
.messages_recent_block_item:hover{
    background: #EBEBEB;
    border-radius: 10px;
}
.messages_recent_block_add_svg{
    fill:  #eb661a !important;
    height: 20px;
    width: 20px;
}
.message_meet_CTA{
    border: none;
    background-color: #fff;
    color: #676767;
    box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.1);
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: 600;
}
.chat_message_box{
    height: 50vh;
}
.chat_message_box {
    height: 62vh;
  }
  
  .message_send_label {
    width: 100%;
    position: relative;
  }
  .message_send_label .fab-container{
    right: 100px;
    bottom: 8.9vh;
    margin: 0;
  }
  .message_send_label .fab-item {
    box-shadow: none !important;
    background-color:  #31b9a3 !important;
  }
  .message_send_label .fab-rotate{
    background-color: transparent !important;
    margin-bottom: -12px !important;
  }
  .message_send_label .fab-item label svg{
    margin-top: 7px;
  }
  
  .message_send_label label{
    cursor: pointer;
  }
  
  .message_btn {
    position: absolute;
  }
  
  .chat_input {
    position: relative;
    width: 100%;
    height: 50px;
    font-family: "Inter";
    border-radius: 5px;
    border: 1px solid #9f9f9f;
    padding: 0px 150px 0 10px;
    line-height: 5;
    word-break: break-all;
  }
  
  .attach_btn {
    background: transparent;
    border: none;
    right: 0;
    height: 50px;
    width: 40px;
    transition: 200ms ease-in-out;
  }
  
  .video_call_btn {
    right: 100px !important;
  }
  
  .send_btn {
    border: none;
    background: transparent;
    right: 0;
    /* right: 42px; */
    height: 50px;
    width: 50px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  .send_btn_img {
    width: 30px;
  }
  .send_btn_img_atch{
      width: 20px;

  }
  
  .no_chat_svg {
    fill: #9f9f9f;
  }
  
  .no_chat_block {
    margin-top: 18%;
  }
  
  .chatmessage {
    margin: 10px 0;
  }
  
  .chatmessage p {
    width: auto;
    margin:0;
  }
  
  .self_message {
    flex-direction: row-reverse;
  }
  
  .mesage_text {
    margin: 0px;
  }
  
  .self_message .mesage_text {
    background-color: #121843;
    padding: 5px 20px;
    border-radius: 10px;
    color: white;
  }
  
  .other_message .mesage_text {
    background-color: #EFEFEF;
    padding: 5px 20px;
    border-radius: 15px;
    color: #000;
  }
  
  .self_message .chat_time {
    text-align: right;
  }
  
  .other_message .chat_time {
    text-align: left;
  }
  .chat_time{
    margin-bottom: 0;
    font-size: 10px;
    color: #CACACA;
  }
  
  .sendFileMsgMain {
    width: 50px;
    display: inline-flex;
    position: absolute;
    right: 65px;
    height: 100%;
    cursor: pointer;
  }
  
  .sendFileMsgMain label {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
  }
  
  .sendFileMsgMain label button {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    cursor: pointer;
    bottom: 0;
    /* z-index: 10; */
  }
  
  .sendFileMsgMain input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    top: 0;
    left: 0;
  }
  
  .sendFileMsgMain label figure {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .deletemsg {
    opacity: 0;
  }
  .deletemsg button .MuiSvgIcon-root{
    fill: #9f9f9f;
  }
  .mdmsg:hover .deletemsg {
    opacity: 1;
  }
  
  .mdmsg:hover .deletemsg svg {
    cursor: pointer;
  }
  
  .deletemsg button {
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
  }
  
  .message_doc-main {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b9b9b9;
    padding: 10px;
    border-radius: 5px;
    border-top-right-radius: 8% 37%;
  }
  
  .doc_download_main button {
    background: #45cbc2;
    color: white;
    padding: 10px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .doc-name-type-main {
    margin-left: 20px;
    margin-right: 10px;
  }
  
  .doc-name-type-main p {
    margin: 0;
    color: white;
    text-transform: capitalize;
    font-family: "Nunito-light";
    color: black;
  }
  
  p.docname {
    font-size: 18px;
    font-weight: 600;
  }
  
  p.doctype {
    font-size: 14px;
  }
  
  .time-type {
    display: flex;
    justify-content: right;
  }
  
  .allfilesIconmain {
    position: absolute;
    bottom: 105px;
    right: 104px;
    padding: 0;
    border-radius: 5px;
  }
  
  .hideFileIcons,
  .hideFile {
    width: 0;
    height: 0;
    transition: 0.3s ease-in-out;
    opacity: 0;
    display: none;
    /* animation: hideIcon 0.3s ease-out; */
    /* display: none; */
  }
  
  .showFileIcon {
    display: inline-block;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  .showFile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    animation: showIcon 0.3s ease-in-out;
    width: 100%;
  }
  
  @keyframes showIcon {
    0% {
      height: 70px;
    }
  
    25% {
      height: 80px;
    }
  
    50% {
      height: 120px;
    }
  
    75% {
      height: 160px;
    }
  
    100% {
      height: 100%;
    }
  }
  
  .filesmain figure {
    margin: 0;
  }
  
  .filesmain svg {
    color: white;
    font-size: 25px;
  }
  
  /* figure#videoIconfile,
  figure#docIconfile,
  figure#photoIconfile {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(
      90deg,
      rgba(104, 193, 210, 1) 0%,
      rgba(43, 210, 182, 1) 100%
    );
  }
  figure#docIconfile {
    background: linear-gradient(
      90deg,
      rgba(104, 193, 210, 1) 0%,
      rgba(43, 210, 182, 1) 100%
    );
  }
  figure#photoIconfile {
    background: linear-gradient(
      90deg,
      rgba(104, 193, 210, 1) 0%,
      rgba(43, 210, 182, 1) 100%
    );
  } */
  
  .filesmain {
    margin-bottom: 10px;
  }
  .filesmain figure {
    cursor: pointer;
  }
  
  .attach_btn figure {
    margin: 0;
  }
  
  .showBlur {
    background: #e3e1e7;
    border-radius: 50%;
    padding: 7px;
    transition: 0.3s ease-in-out;
  }
  
  .hideBlur {
    background: transparent;
    border-radius: 0;
    padding: 0px;
    transition: 0.3s ease-in-out;
  }
  
  .filesmain input {
    /* width: 100%; */
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 0;
  }
  .message_attachment_popper{
    background: white;
    padding: 5px;
  }
  .message_attachment_popper svg{
    background: linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%);
    border-radius: 50%;
    color: #fff;
    padding: 5px;
    height: 35px;
    width: 35px;
    margin: 7px;
    cursor: pointer;
  }
  .sendFileMsgMain .MuiButton-root{
    background: transparent;
    box-shadow: none;
    min-width: 45px;
    margin-right: 0px;
  
  }
  .sendFileMsgMain .MuiButton-root:hover{
    background: transparent;
    box-shadow: none;
  }