.dashboard01_navnew {
    width: 100%;
    height: 9.05vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.dashboard_left_new_logo {
    width: auto;
}
.dashboard01_new_logo img {
    width: 60%;
}
.dashboard01_new_logo {
    padding-left: 15px;
}