.signup-checkbox a {
  color: white;
}
.registration {
  margin-top: 10vh;
  /* height: 80vh; */
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.reg_form {
  padding: 20px;
}
.regform {
  align-items: center;
  margin: auto;
  margin-top: 5vh;
  background: black;
  width: 50vw;
  padding: 3vh;
  border-radius: 5px;
}

.goalsdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.goalsdiv input {
  width: 88% !important;
}
.goalsdiv button {
  background: #262C5B;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #fff;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.goalsvalues {
  display: flex;
  align-items: center;
}
.goalsvalues p {
  margin: 0;
  color: white;
  white-space: break-spaces !important;
  word-break: break-all !important;
  text-align: left;
}

.goalsvalues button {
  border: none;
  outline: none;
  background: transparent;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.goalsscroll {
  /* max-height: 50px;
  overflow-y: auto;
  overflow-x: hidden; */
  overflow-x: hidden;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
/* .goalsscroll::-webkit-scrollbar {
  opacity:0;
} */
.program_box{
  display: flex;
  width: fit-content;
}
.program_btn{
  border: none;
  background: transparent;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
}
.program_text{
  font-size: 14px;
}