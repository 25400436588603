.excelmails{
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.incubation_paticipation_program_TabsMain {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 30px; */
    border-bottom: 1px solid #E4E4E4;
}