.application-box-one{
    background: #F8F9FA;
    border: 0.1px solid rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 4px 8px;
}
.application-box-two{
    background: rgba(255, 111, 30, 0.1);
    border-radius: 10px;
}
.application-box-three{
    background: rgba(38, 44, 91, 0.1);
    border-radius: 10px;
}
.drop-design{
    position: absolute;
    width: 225px;
    background: #fff;
    max-height: 77px;
    z-index: 2;
    overflow-y: scroll;
}
.program_select{
    border: none;
    background: transparent;
    width: 100%;
}
.program_select:focus{
    outline:none;
}