.incubation_create_courseFirst2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
}

.incubation_create_courseFirst2 img {
    width: 40px;
    height: 40px;
}

.user_incubation_course_rightMain {
    padding: 24px 0;
}

.user_incubation_course_AvatarGroup .MuiAvatar-root {
    border: 1px solid #fff !important;
    margin-left: -20px !important;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    background: rgba(196, 196, 196, 0.8);
    color: black;
}
.user_incubation_course_AvatarGroup .MuiAvatar-root:last-child {
    margin-left: 0 !important;
}
.user_incubation_course_AvatarGroup_root {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.user_incubation_course_startupEnrolled {
    margin-left: 12px;
}
.user_incubation_course_leftSide {
    height: 100%;
    border-left: 1px solid #e6e6e6;
    padding-left: 20px;
    padding-right: 24px;
}

.incubation_courses_card_root2 {
    position: relative;
}

.user_incubation_course_btns {
    position: absolute;
    right: 10px;
    top: 10px;
}

.user_incubation_course_btns>button {
    margin-right: 6px;
    color: #989898;
}