.dashboard_adv{
    background: #FFFFFF;
    border-radius: 12px;
    height: 100%;
    /* padding: 16px; */
}
.dashboard_adv_top{
    padding: 16px;
    border-radius: 12px 12px 0px 0px;
    background: url(`./../../../../Assets/images/advertisement.png) no-repeat center center;
    background-size: cover;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.dashboard_adv_top p{
    color: rgb(0,0,0);
    width: 70%;
    position: relative;
    z-index: 3;
}
.dashboard_adv_top::before{
    position: absolute;
    content: ' ';
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 12px 12px 0px 0px;
}
/* .dashboard_adv_bottom{
    padding: 16px;
} */
.ad_span{
    width:18px;
    height: 14px;
    padding: 1px 3px;
    background: #C1D1E6;
    border-radius: 2px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 12px;
    text-transform: uppercase;
    color: #000000;
    position: absolute;
    z-index: 4;
    top: 10px;
    right: 10px;
}