.startup_assets_card_root {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgb(48 48 48 / 8%);
    border-radius: 12px;
}

.startup_assets_card_main {
    padding: 12px 16px 16px 16px;
}

.startup_assets_card_profile {
    display: flex;
    align-items: center;
}

.startup_assets_card_profile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.startup_assets_card_rs_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.startup_assets_card_rs {
    display: flex;
    align-items: center;
}

.startup_assets_card_rs svg {
    height: 20px;
    width: 20px;
    margin-right: 4px;
}

.startup_assets_card_incubator_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.startup_assets_card_incubator_profile {
    display: flex;
    align-items: center;
}

.startup_assets_card_incubator_profile img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.startup_assets_card_footer {
    background: rgba(88, 1, 103, 0.2);
    border-radius: 0px 0px 12px 12px;
    padding: 6px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.startup_assets_ellipse {
    height: 4px;
    width: 4px;
    background: #565656;
    border-radius: 50%;
    margin: 0 4px;
}