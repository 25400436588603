.coworking_addAssets_form_root {
    background: #FFFFFF;
}

.coworking_back_btn {
    border: none;
    background: transparent;
    outline: none;
    /* cursor: pointer; */
}

.coworking_addAssets_form_head {
    padding: 16px 24px;
    border-bottom: 1px solid #e4e4e4;
}

.coworking_addAssets_form_main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.coworking_addAssets_form_div {
    width: 75%;
}

.coworking_addAssets_form_main2 {
    padding: 56px 0 32px 0;
}

.coworking_addAssets_input_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.coworking_addAssets_input_main label {
    margin: 0;
    margin-bottom: 6px;
}

.coworking_addAssets_input_main>input,
.coworking_addAssets_input_main>select {
    border: none;
    border-bottom: 1px solid #98B3D6;
    padding-bottom: 6px;
    outline: none;
}

.coworking_addAssets_btn_main{
    margin-top: 28px ;
}

.coworking_addAssets_input_add {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #98B3D6;
    padding-bottom: 6px;
}

.coworking_addAssets_input_add input {
    width: 100%;
    border: none;
    outline: none;
}

.coworking_addAssets_input_add button {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #262C5B;
    color: white;
    border: none;
    outline: none;
    font-size: 20px;
    border-radius: 4px;
}

.asset_details_arr {
    display: flex;
    align-items: center;
}

.asset_details_arr button {
    background: transparent;
    outline: none;
    border: none;
    font-weight: 600;
    color: red;
    font-style: italic;
    cursor: pointer;
    margin-left: 10px;
}