.viewStartupCouse_main_cardsubInfo {
    padding: 20px 34px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.viewStartUpCourse_card_subInfoTags {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
}
.viewStartUpCourse_card_subInfoTags span svg {
    width: 0.8em;
    fill: #98B3D6;
    margin-right: 9px;
}
.viewStartupCouse_main_card{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}
.viewStartupCourse_content_Accordion:first-child {
    border-radius: 10px 10px 0 0 !important;
}
.viewStartupCourse_content_Accordion:last-child {
    border-radius: 0 0 10px 10px !important;
}
.viewStartupCourse_content_Accordion {
    padding: 5px 0;
}
.viewStartupCourse_content_session_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
    transition: all 0.2s linear;
}
.viewStartupCourse_content_session_main:hover{
    background: rgba(226, 232, 240, 0.3);
}
.viewStartupCourse_content_session_name span svg {
    width: 16px;
    height: 16px;
    /* margin-right: 9px; */
}
.user_incubation_course_rightside {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}