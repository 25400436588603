.dashboard_mentor_card {
  min-height: 175px;
  background: #ffffff;
  box-shadow: 0px 3px 20px rgba(48, 48, 48, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
}
.side_box{
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 24px;
}
