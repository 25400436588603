.incubation_profile__head_card1_root {
    background: #121843;
    border-radius: 10px;
    color: white;
    padding: 17px;
    height: 100%;
}
.incubationprofile_card1_head {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
}
.incubationprofile_card1_title {
    margin-top: 18px;
    padding-right: 22px;
}
.incubationprofile_card1_link {
    margin-top: 15px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 1px;
    color: #989898;
    margin-bottom: 16px;
}

.incubationprofile_card1_hostedPro {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.incubation_profile_program_TabsMain {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    border-bottom: 1px solid #E4E4E4;
}
.incubation_profile_program_Tabs .MuiTabs-indicator {
    display: none
}

.incubation_profile_program_Tab {
    font-family: Inter !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-align: center !important;
    letter-spacing: 1.25px !important;
    text-transform: uppercase !important;
    color: #989898 !important;
}
.incubation_profile_program_Tabs .MuiTabs-flexContainer{
    display: flex;
    align-items: center;
}
/* .incubation_profile_program_Tab:nth-child(2)
{
    border-left: 1px solid black;
    border-right: 1px solid black;
} */
.incubation_profile_program_Tab.Mui-selected{
    color: rgb(0,0,0) !important;
}
.incubation_profile_program_Tab .MuiButtonBase-root{
    margin: 0 10px !important;
}
span.incubation_profile_program_span {
    height: 25px;
    width: 1px;
    background: rgb(0,0,0);
    color: rgb(0,0,0);
}

.incubation_profile_card_head {
    height: 65px;
    background: rgba(88, 1, 103, 0.1);
    position: relative;
}
.incubation_profile_card_main {
    background: #FFFFFF;
    box-shadow: 0px 3px 20px rgb(48 48 48 / 10%);
    padding-bottom: 2px;
}
.incubation_profile_card_profile {
    height: 64px;
    width: 64px;
    /* background: blue; */
    position: absolute;
    border-radius: 10px;
    /* top: 50%; */
    left: inherit;
    right: 50%;
    transform: translate(50%, 50%);
}
.incubation_profile_card_profile img {
    width: 100%;
}
.incubation_profile_card_title {
    margin-top: 40px;
}

.incubation_profile_card_title p {
    font-family: Spartan;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 1px;
    color: #262C5B;
}

.incubation_profile_card_subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
}
.incubation_profile_card_subtitle p {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    color: #565656;
}
.incubation_profile_card_subtitle span {
    width: 2px;
    color: rgb(0,0,0);
    background-color: #565656;
    height: 16px;
}
.incubation_profile_card_chip {
    display: flex;
    margin-top: 15px;
    padding: 3px 10px;
    margin-bottom: 15px;
    justify-content: center;
}

.incunation_shortlisted_main {
    background: #FFFFFF;
    box-shadow: 0px 3px 20px rgb(48 48 48 / 10%);
    padding: 13px 10px;
}

p.transparntChip {
    border: 1px solid #262C5B;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #262C5B;
    padding: 3px 9px;
}
.back{
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #989898;
    margin-right: 30px;
    cursor: pointer;
}

.backhead{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: rgb(0,0,0);
}
.overline{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: rgb(0,0,0);
}
.color-black{
    color: rgb(0,0,0) !important;
}
.blueout_btn{

    padding: 8px 16px !important;
    text-align: center;
    width: fit-content;
    color: rgb(0,0,0) !important;
    /* margin-top: 10px; */
    font-weight: 600;
    border: 2px solid #262C5B;
    box-sizing: border-box;
    /* height: 45px; */
    border-radius: 30px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
}
.founder-title{
    font-family: Nunito;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: rgb(0,0,0);
}
.founder-job{
    font-family: Nunito;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #757575;
}
.outreach_service_box{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.08);
    border-radius: 10px;
}
.grey-pill{
    padding: 3px 11px;
    background: #E2E8F0;
    border-radius: 12px;
}