.incubator_create_program_head h4{
     color: var(--fourthBlack);
 }
 .incubator_create_program_main {
     width: 100%;
     background: white;
     padding: 60px 120px;
     margin-top: 1rem;
 }
 .program_upload_img {
     width: 100%;
     background: #FFFFFF;
     box-shadow: 0px 4px 20px rgb(48 48 48 / 10%);
     border-radius: 8px;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 10px;
     margin-top: 14px;
     /* cursor: pointer; */
 }
 .program_upload_img input {
     position: absolute;
     left: 0;
     cursor: pointer;
     width: 0;
     height: 0;
     opacity: 0;
 }
 .program_upload_img p {
     margin: 0;
     cursor: pointer;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
 }

 