.incubator_coworking_myAssets_left_root {
    width: 100%;
    background: white;
}

.coworking_myAssets_left_head {
    padding: 20px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E4E4E4;
}

.addAssetFormbtn {
    background: linear-gradient(259.04deg, #F53939 4.27%, #FF6F1E 23.72%, #FFC939 99.98%);
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    height: 33px;
    width: 33px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.coworking_myAssets_left_profile {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E4E4E4;
    position: relative;
}

.coworking_myAssets_left_profile_img img {
    width: 41px;
    height: 41px;
    border-radius: 50%;
}

.coworking_myAssets_left_active {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(18, 24, 67, 0.2);
    padding: 2px 8px;
}


.coworking_myAssets_right_details_main {
    background: white;
}

.coworking_myAssets_right_details_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 30px 24px 18px;
}

.coworking_myAssets_right_details_id {
    background: rgba(226, 232, 240, 0.6);
    display: flex;
    padding: 8px 0;
}

.coworking_myAssets_right_details_id_status {
    width: 25%;
    padding-left: 24px;
    border-right: 1px solid #E4E4E4;
}

.coworking_myAssets_right_details_id_status:last-child {
    border-right: none;
}

tr.myAssets_right_category_box_table_tr td:first-child {
    width: 50%;
    padding-top: 24px;
    padding-left: 24px;
}

tr.myAssets_right_category_box_table_tr td:last-child {
    width: 50%;
    padding-top: 24px;
}

.myAssets_right_category_box_main {
    border-right: 1px solid #E4E4E4;
}

.coworking_myAssets_booking_detail {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.coworking_myAssets_booking_cabin {
    width: 68.4%;
}

.coworking_myAssets_bookings_summ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
}

.coworking_myAssets_bookings_profile {
    display: flex;
    align-items: center;
}

.coworking_myAssets_bookings_profile img {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    margin-right: 8px;
}

.coworking_myAssets_right_bookings_head{
    display: flex;
    align-items: center;
}

.coworking_myAssets_right_bookings_head img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

.coworking_myAssets_bookings_Acc {
    box-shadow: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border: none;
}

.coworking_myAssets_right_editbtn {
    border-radius: 100px;
    border: 1px solid #F53939;
    background: transparent;
    padding: 4px 16px;
    outline: none;
}

.coworking_myAssets_left_profile:hover{
    background: rgba(226, 232, 240, 0.6);
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}

table.myAssets_right_category_box_table {
    width: 100%;
}
.background{
    background:#fff !important;
}