.incubation_create_courseFirst {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-height: 200px;
}
.incubation_courses_card_main {
    padding: 20px;
}
.incubation_courses_card_type_main {
    border: 0.7px solid #E1E1E1;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    height: 36px;
    /* align-items: center; */
}
.incubation_courses_card_type_fill {
    width: 8px;
    height: auto;
    border-radius: 0px 8px 8px 0px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    background-color: red;
}
.incubation_courses_card_type_text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.incubation_course_card_startups {
    display: flex;
    margin-top: 3px;
}
.incubation_courses_card_footer {
    border-radius: 0px 0px 20px 20px;
    background: #DEDFE6;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active_marketing{
    background: #262C5B;
}
.inactive_marketing{
    background: #870015;
}
.active_footer{
    background: #DEDFE6;
}
.inactive_footer{
    background: #E7CCD0;
}