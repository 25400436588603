.signup-main.container {
  height: 88vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup_from-main {
  background: #000;
  padding: 30px 4.6rem;
  border-radius: 10px;
}
.auth_input_password {
  width: 100%;
}
.auth_input_pas_btn {
  position: absolute;
  border: none;
  background-color: transparent;

  bottom: 32%;
  right: 5%;
}

.signup-form-head {
  width: 100%;
  text-align: center;
  padding: 25px;
  color: white;
}

.signup_from-main form {
  width: 100%;
  padding: 13px 15px;
}

.signup-form-div2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signup-form-div2 > div {
  width: 48%;
}

.signup-form-div {
  width: 100%;
}

.signup-form-div input {
  width: 100%;
  border: 0.5px solid #989898;
  margin: 6px 0;
  background: transparent;
  border-radius: 4px;
  font-size: 18px;
  padding: 5.5px 12px;
  color: white;
}

.signup_from-main form ::placeholder {
  color: white;
}

.signup-checkbox {
  display: flex;
  padding: 5px 0;
  align-items: center;
}

.signup-check {
  width: 30px;
  height: 30px;
  /* font-size: 30px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.signup-check input {
  height: 24px;
  width: 24px;
}

.signup-check-text {
  padding: 0 6px;
}

.signup-check-text p {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.08em;
  color: #cacaca;
}

 .signup-btn {
  margin: 30px 0 10px 0;
  width: 100%;
  display: flex;
}

.signup-btn button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 0;
}

/*.signup-btn button:hover {
  color: black;
  font-weight: bold;
  background: linear-gradient(180deg, #ffc939 0%, #f48361 100%);
} */

.signup-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.signup-options button {
  width: 49%;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 6px 10px;
}

.signup-options button:first-child {
  background: #4285f4;
}

.signup-options button:nth-child(2) {
  background: #0077b5;
}

.signup-options button p {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #ffffff;
  padding: 0 10px;
}

.alreadyMember-main {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alreadyMember-main p {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.08em;
  color: #cacaca;
}

.alreadyMember-main p a {
  color: white;
}

.or-root {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cacaca;
}

.or-root span {
  width: 13px;
  color: #cacaca;
  height: 1px;
  background: #cacaca;
}

.or-root p {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.08em;
  padding: 0 3px;
}

/* ------------ main css half black---------------- */

.auth_back_part {
  width: 100%;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex: 1;
}
.twf_signup_left {
  height: 100%;
  background: black;
  padding: 5rem 6.5rem;
  width: 50%;
}
.twf_signup_right_main {
  padding: 5rem;
  position: relative;
  width: 50%;
  /* overflow: visible; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
/* .auth_back_part::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: #000;
  height: 100vh;
  width: 50vw;
  z-index: -1;
} */
.auth_image {
  width: auto;
}
.auth_back {
  font-size: 20px;
  color: #fff;
}
.auth_back i {
  font-size: 20px;
  margin-right: 10px;
}
.auth_back_loginform input,
.auth_back_loginform select,
.auth_back_loginform textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid #98b3d6;
  /* margin: 0 0 6px 0; */
  background: transparent;
  /* border-radius: 4px; */
  /* font-size: 18px; */
  padding: 8px 0px 10px 0;
  /* color: white; */
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #636363;
}
.auth_back_loginform input:focus,
.auth_back_loginform select:focus,
.auth_back_loginform textarea:focus {
  outline: none;
}
.auth_back_loginform label {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #262c5b;
  margin: 24px 0 0 0;
}
.auth_back_loginform option {
  line-height: 10px;
  margin: 5px 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding: 5.5px 0px;
  color: #636363;
}
.auth_back_loginform-text {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  color: #6d6d6d;
}
@media (max-width: 1007px) {
  .twf_signup_left {
    padding: 3rem 2.5rem;
  }
  .twf_signup_right_main {
    padding: 4rem;
  }
}
@media (max-width: 767px) {
  .auth_back_part{
    flex-direction: column;
  }
  .twf_signup_left {
    width: 100%;
    padding: 3rem 6.5rem;
  }
  .twf_signup_right_main {
    width: 100%;
    padding: 4rem 6.5rem;
  }
}
@media (max-width: 510px) {
  .twf_signup_left {
    padding: 3rem 3.5rem;
  }
  .twf_signup_right_main {
    padding: 3rem 3.5rem;
  }
}

@media (max-width: 350px) {
  .twf_signup_left {
    padding: 3rem 2.5rem;
  }
  .twf_signup_right_main {
    padding: 3rem 2.5rem;
  }
}

