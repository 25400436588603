.react-calendar {
  /* width: 400px; */
  max-width: 100%;
  height: auto;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border:none;
}
.react-calendar__navigation {
  height: 10vh;
}
.react-calendar__navigation button {
  color: #000;
  min-width: 44px;
  background: none;
  font-size: 18px;
  margin-top: 8px;
  font-family: Spartan;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #f48361;
  border-radius: 6px;
}
.react-calendar__tile--now {
  background: #f4836133;
  border-radius: 6px;
  font-weight: bold;
  color: #f48361;
}
.calendar-event-item{
  margin: 5px 0;
  background:rgba(226, 232, 240, 0.3);
  padding: 5px 4px;
  text-align: left;
}
.react-calendar__tile{
  padding: auto 0px !important;
  font-weight: 700;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #f4836133;
  border-radius: 6px;
  font-weight: bold;
  color: #f48361;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  background: #f8f9fa;
  border-radius: 6px;
  font-weight: bold;
  color: #303030;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #f48361;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--range {
  background: #f8f8fa;
  color: #f48361;
  border-radius: 0;
}
.react-calendar__tile {
  height: 150px;
  width: 100px;
}

.react-calendar__tile--rangeEnd {

  background: #f8f9fa;
  border-radius: 6px;
  font-weight: bold;
  color: #303030;
}

#parent-modal-description {
  margin: 10px 0px;
  padding: 5px;
}
.color-tab {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
}

/* WEEKS CSS */

.table thead th {
  vertical-align: bottom;
  border-bottom: none;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none;
}
.curriculam-box {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-left: 3px solid #decce1;
  border-radius: 4px;
  position: relative;
  min-height: 56px;
  margin: 40px 0 ;
}
/* .curriculam-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: #decce1;
} */
.edit_btn {
  background: rgb(88, 1, 103, 0.3);
  padding: 4px;
  display: flex;
  height: 18px;
  width: 18px;
  justify-content: center;
  align-content: center;
  border-radius: 4px;
  opacity: 0;
  transition: 0.5s ease;
  position: absolute;
  top: 4px;
  right: 4px;
}
.curriculam-box:hover{
  box-shadow: 0px 4px 20px rgba(48, 48, 48, 0.08);
}
.curriculam-box:hover .edit_btn {
  opacity: 1;
}
.react-calendar__month-view__weekdays__weekday,.react-calendar__tile{
  border:1px solid #E4E4E4 !important;
}


.event-checkbox  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em red;
  }
  
  .event-checkbox  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  
