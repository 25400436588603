.auth_back_loginform .eventCover {
  background-color: rgb(242, 242, 242);
  width: 100%;
  height: 160px;
  text-align: center;
  cursor: pointer;
  border-image: initial;
  transition: all 0.2s ease-in-out 0s;
  border-radius: 5px;
  position: relative;
  /* border: 1px solid; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth_back_loginform .eventCover input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  cursor: pointer;
}
.valuation_head{
  font-family: Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: #121843;
  margin:50px auto;
}
.del-box {
  z-index: 4;
  transition: 0.5s ease-in;
}
.del-box:hover {
  background-color: #989898;
}
.del-box #coverUploadfigure {
  z-index: 2;
  margin: 16px 0px;
  display: flex;
  justify-content: center;
  position: relative;
}
.del-box .del-btn{
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  cursor: pointer;
  display: none;
}
.del-box:hover .del-btn{
  display: block;
}
