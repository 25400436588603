.startupLooking_body_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F8F9FA;
    border-radius: 8px;
    padding: 8px 16px;
    margin-bottom: 16px;
}

.startupLooking_body_div:last-child{
    margin-bottom: 0;
}

.startupLooking_body_divleft {
    display: flex;
    align-items: center;
}

.startupLooking_body_divleft img {
    border-radius: 12px;
    width: 44px;
    height: 44px;
}

.startupLooking_body_divright p {
    color: #FF6F1E;
}