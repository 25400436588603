.incubation_create_courseFirst {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 200px;
}

.onboarding_cards {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 20px rgb(48 48 48 / 10%);
}

.create_course_root {
  align-items: center;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  margin: auto;
  min-height: 83vh;
  padding: 0 100px;
  width: 100%;
}

.newgoals {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
