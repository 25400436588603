.invoice_item_main {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
.invoice_item_main>input {
    border: 1px solid #98B3D6;
    border-radius: 8px;
    height: 35px;
    outline: none;
    padding: 10px;
    width: 100%;
}

.removebtn {
    color: red;
    font-size: 14px;
}