.newTask-headImg {
  width: 40px;
  height: 40px;
  background: rgba(53, 130, 66, 0.05);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.completeAssignment {
  border: 1px solid #358242;
}
.inCompleteAssignment {
  border: 1px solid #fbc740;
}
.task-dash-root {
  
  border-radius: 4px;
  padding: 10px;
}
.checklist_index{
  font-size: 16px;
  font-weight: bold;
  margin-right:10px;
}
.MuiCheckbox-root{
  /* color: #2EB67D !important; */
  height: 25px !important;
  width: 20px !important;
}

.newTask-addFile-link input {
  background: #ffffff;
  border: 0.25px solid #404654;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 12px;
  padding: 4px;
}
.newTask-addFile-imgLabel {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 17px 0 2px 0;
}
label.newTask-addFile-imgLabel svg {
  fill: var(--primary-color);
}
.newTask-main ::placeholder {
  color: #757575;
}
/* .newTask-addFile-link ::placeholder {
    color: 757575;
} */
.newTask_assignTO {
  max-height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 2px 1px 4px 1px #c5bfbf;
  border-radius: 0 0 4px 4px;
}
.bg_cyan {
  background: #7bbfb829;
}
.partner_members_row {
  padding: 0;
  margin: 5px 0;
}
.addProblem-mai {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addProblem-mai input {
  width: 90%;
}
.addProblem-mai button {
  width: 25px;
  height: 25px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #123769;
  border-radius: 10%;
  cursor: pointer;
  margin-left:10px;
}
.newTask-problem {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  align-items: center;
}
.partner_addtask_form textarea {
  min-height: 80px;
}
.newtask-partnern_assignedMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newTask-assignedto-main {
  width: 62%;
}
.newTask-duedate-main {
  width: 34%;
}
.popOver-rootTask {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10px;
}
.popOver-rootTask p{
  cursor: pointer;
  padding:5px 5px;
  width: 100%;
}
.popOver-rootTask p:hover{
  background: rgba(226, 232, 240, 0.4);

}

.task-collab_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}
.task_uncmpltebtn {
  border-radius: 8px;
  padding: 12px;
  transition: all 0.2s ease;
}
.task_uncmpltebtn:hover {
  background-color: #efefef;
}
.task-edit_btn {
  transition: all 0.2s ease;
}
.task-edit_btn:hover {
  background: #efefef;
}
.mentor_room_head_block{
  border-radius: 10px;
  padding: 20px 20px;
  box-shadow: 0px 3px 20px rgba(48, 48, 48, 0.1);
}
.newTask-card-main{
  background: rgba(226, 232, 240, 0.4);
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.create_task_form label{
    font-size: 14px;
    width: 100%;
    color: rgba(38, 44, 91, 1);
    font-weight: 600;
    font-family: Inter;
    margin-top: 10px;
}
.create_task_form input{
  border: none;
  border-bottom: 1px solid #98B3D6;
  font-size: 15px;
  width: 100%;
  font-weight: 500;
  font-family: Inter;
}
.create_task_form textarea{
  border: none;
  border-bottom: 1px solid #98B3D6;
  font-size: 15px;
  width: 100%;
  font-family: Inter;
  min-height: 50px;
}
.create_task_form input:focus, .create_task_form textarea:focus{
outline: none;
}