.mentor_startups_root {
    background: white;
    border-radius: 12px;
    padding: 16px;
    height: 100%;
}
.mentor_startups_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}
.mentor_startups_body {
    background: rgba(248, 249, 250, 0.35);
    border-radius: 8px;
}
.mentor_startups_body_head {
    height: 48px;
    position: relative;
    background: rgba(226, 232, 240, 0.4);
    border-radius: 8px 8px 0px 0px;
}
.mentor_startup_body_head_profile {
    position: absolute;
    bottom: -50%;
    left: 50%;
    transform: translateX(-50%);
}
.mentor_startup_body_head_profile img {
    width: 56px;
    border-radius: 5px;
}
.mentor_startups_name_body {
    width: 100%;
    margin-top: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.mentor_startups_tags {
    display: flex;
    align-items: center;
}