.nav_root {
    width: 100%;
    background-color: var(--transparent);
    height: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

nav{
    z-index:100;
}
.nav_main {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.nav_logo {
    width: 186px;
    height: 40px;
}

.nav-menus ul {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    align-items: center;
}

.nav-menus ul li {
    padding: 0 25px;
}

.nav-menus ul li a {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}
.nav-signup{
    border-radius: 100px;
    padding: 11px 16px !important;
    border-top: 1px solid #F48361;
    border-left: 1px solid #F48361;
    border-bottom: 1px solid #FFC939;
    border-right: 1px solid #FFC939;
    
}
.nav-signup a{
    background: transparent;
}
.nav-li a{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.text-dark {
    color: #303030!important;
}
.nav_btn{
    background: none;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.navdrop_box{
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
}
.downdown-menu .dropdown-item.active, .dropdown-item:active{
    background-color: #f2f2f2 !important;
}
.dropdown-item{
    cursor: pointer;
}
.dashboard01_nav .MuiMenuItem-root {
    width: 100%;
    /* margin: 5px 20px; */
    justify-content: left;
 
}
.navbar_menu_icon{
    background: transparent;
    border:none;
}
.navbar_menu_icon .MuiSvgIcon-root{
    fill: #ffffff;
    height: 30px;
    width: 30px;
}
.dropdown-toggle::after {
    margin-left: 16px;
}
@media screen and (max-width:992px) {
    .navbar-collapse{
        position: absolute !important;
        top: 70px;
        left: 0;
        background: #000;
        width: 100%;
    }
}
@media screen and (max-width: 767px) {
    .nav-menus ul {
        display: none;
    }
    .navbar-nav .navli{
        margin-right: 0!important;
        padding: 5px 0px;
        margin: 10px 0;
        align-self: center;
    }
}
@media screen and (max-width: 426px) {
    .navbar-nav{
        color:#fff;
        background-color: transparent;
    }
    
}
