@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");

#jitsi-container iframe {
  height: 100vh;
}
.eventsForm {
  width: 83%;
  margin: 0 auto;
}
.eventsForm .savenextbtn {
  width: 80%;
  margin: 15px auto 0 auto;
  display: flex;
  justify-content: space-evenly;
}
.eventsForm .savenextbtn button {
  padding: 0;
  width: 272px;
  height: 50px;
}
.eventsForm .savenextbtn .back_GTA {
  padding: 0;
  width: 272px;
  height: 48px;
  border: 3px solid #ffffff;
  margin-top: 10px;
  color: #757575;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .eventsForm {
    width: 100%;
  }
}
