.dashboard_01_connections_list_item{
    border-radius: 0;
    margin-bottom: 15px;
    box-shadow: 0px 3px 20px rgba(48, 48, 48, 0.1);

}
.dashbaord_connections_inner_tabs .MuiTabs-flexContainer{
    justify-content: center;
}
.dashbaord_connections_inner_tabs .MuiTab-root{
    padding: 0px 40px;
    border-right: 2px solid #000;
    font-size: 15px !important;
    font-weight: 600 !important;
    min-height: 30px;
    color: #989898;
}
.dashbaord_connections_inner_tabs .MuiTab-root:last-child{
    border-right: none;
}
.tab_br_2 {
    padding: 10px 30px;
    /* border-right: 1px solid red !important; */
}
/* .tab_br_1{ */
    /* padding: 10px 30px; */
    /* border-right: 1px solid red !important; */
    /* border-right: none !important; */
    /* border-left: 1px solid red !important; */
/* } */
.dashbaord_connections_inner_tabs .MuiTabs-indicator{
    display: none;
}
.connection_search .connection_serach_input{
    width: 100%;
    position: relative;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 7px ;
    padding-left:10px;
    background-color:#E4E4E4;
    border: none;
}
.connection_search .connection_serach_input:focus{
    outline: none;
}
.connection_search_button button{
    border-radius: 20px;
    border: 2px solid #000;
    background: #fff;
    position: absolute;
    top:0;
    right: 0;
    padding: 5px 10px;
}
.dashboard_01_connections_logo_list_block{
    background: rgba(88, 1, 103, 0.1);
    text-align: center;
    width: 100%;
    height: 63px;
    padding: 25px 0;
    margin-bottom: 33px;
}
.dashboard_01_connections_logo_list_block .MuiAvatar-root{
    border-radius: 20%;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    /* margin-bottom: -35px; */
    width: 64px;
    height: 64px;
    background-color: #fff;
}
/* .dashboard_01_connections_logo_list_block .MuiAvatar-root img{

} */
.dashboard_01_connections_list_item_desc{
    padding: 16px 0 21px;
    text-align: center;
}

.dashbaord_connections_inner_tabs p{
    font-weight: 600;
}
.gsd_pagination{
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px !important;
}

.connection_profile_root{
    background-color: #fff;
    border-radius: 10px;
    padding: 12px;
}
.connection_profile_root_highligts{
    background-color: #fff;
    border-radius: 10px;
}
.connection_profile_root_highligts_head{
    background: rgba(226, 232, 240, 0.4);
    padding: 20px 12px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

}
.connection_profile_root_highligts_desc{
    padding: 12px;
}
.connection_profile_root_highligts_desc table {
    width: 100%;
}
/* .connection_profile_root_highligts_desc table tr{

} */
.connection_profile_root_highligts_desc table td{
    width: 50%;
    padding:10px 0;
}
.connection_profile_root_highligts_desc table .td_left{
 font-weight: 600;
 font-family: 'Inter';
}
.connection_request_item{
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 3px 20px rgba(48, 48, 48, 0.1);
    cursor: pointer;
    text-transform: capitalize;
}
.connection_request_item .row{
    align-items: center;
}