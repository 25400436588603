@import "./Components/Css/common.css";


* {
    scrollbar-width: thin;
    scrollbar-color: #f1f1f1 #888;
}
*::-webkit-scrollbar {
    width: 5px;
}
  
  /* Track */
*::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
  /* Handle */
*::-webkit-scrollbar-thumb {
    background: #888;
}
  
  /* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.CookieConsent {
  left: auto !important;
  right: 0px !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  align-items: center !important;
}
.cookie_img {
  height: 40px;
}
.CookieConsent button:hover {
  color: #000 !important;
}