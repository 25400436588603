.dashboard01_nav{
    position: absolute;
    display: flex;
    width: 82%;
    left: 18.9%;
    background-color: #ffffff;
    padding: 10px 0;
    padding-right: 0px;
}
.dashboard01_nav .dashboard01_icons{
    margin: 0 15px;
}
.dashboard01_nav .MuiButton-text{
    padding: 0;
    min-width: fit-content;
    margin: 0 15px;
}
.dashboard01_nav .MuiButton-root {
    color: #000;
}
.dashboard01_nav .MuiBadge-badge{
    background: #000;
}
.dashNav_profile_heighted {
    border-radius: 0 !important;
    border-left: 1px solid #989898 !important;
    padding-left: 16px !important;
    margin: 0 15px 0 4px !important;

}
.feed_nav_cta{
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    background: #e2eaf0;
    border-radius: 100px;
    padding: 10px 18px;
    border-top-left-radius:100px;
    border-bottom-left-radius:100px;
}
.feed_nav_cta svg{
    margin: 0;
    margin-left: 10px;
}

@media (max-width:1150px){
    .dashboard01_nav{
        width: 81.5%;
        left: 19.5%;
    }
}
@media (max-width:992px){
  
}

@media (max-width:768px){
    .dashboard01_nav{
        width: 90%;
        left: 12%;
    }
}

@media (max-width:600px){
    .dashboard01_nav{
        width: 82%;
        left: 17%;
        padding-right: 0;
    }
}
@media (max-width:430px){
    .dashboard01_nav{
        width: 82%;
        left: 17.5%;
        padding-right: 0;
    }
}
