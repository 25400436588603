.signup-checkbox a {
    color: white;
}
.registration{
    margin-top: 10vh;
    /* height: 80vh; */
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}
.reg_form{
    padding: 20px;
}
.regform{
    align-items: center;
    margin: auto;
    margin-top: 5vh;
    background: black;
    width: 50vw;
    padding: 3vh;
    border-radius: 5px;
}

/* ------------ new ui register css---------------- */

.regis nav{
    position: absolute;
    left: 50%;
    top:10px;
    transform: translate(-50%);
}
.regis nav .navbar-collapse .navbar-nav .nav-li a{
    color:#000;
    font-weight: 600;
  }
  .regis .nav_btn{
    color:#000;
  }
  .register_page{
    width:100%;
    height: auto;
  }
  .register_page::before{
    content:'';
    position: fixed;
    z-index: -1;
    width: 35%;
    height: 100%;
    background-color: #000;
  }
  .register_page_sideborder{
    border-left: 1px solid #fff;
    padding:20px 0px 2px 20px;
  }
  .register_page_sideborder-head{
    font-family: Spartan;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
  }
  .register_page_sideborder-sidehead, .register_page_sideborder-sidehead-active{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.25px;
    margin-bottom: 20px;
    color: #9F9F9F;
  }
  .register_page_sideborder-sidehead-active{
    color:#fff;
  }
  .register_page_form_upload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.register_page_form_upload-text{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1.25px;
    color: #000000;
}
.register_page_form_upload input[type="file"] {
    position: absolute;
    opacity: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    width: 65px;
    height: 65px;
    border-radius: 50%;
}
.register_page_form_com_logo{
    border: 1px solid #9f9f9f;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    font-size: 0.7rem;
    cursor: pointer;
    color: #fff;
    width: 100px;
    height: 100px;
    background: #000000;
    border-radius: 15px;
}

.register_page_form label {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    color: #262C5B;
    margin: 24px 0 0 0;
}
.register_page_form .MuiInputBase-input {
border-bottom: none;
}
.register_page_form input, .register_page_form select,.register_page_form textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid #98B3D6;
    margin: 0;
    background: transparent;
    /* border-radius: 4px; */
    /* font-size: 18px; */
    padding: 8px 0px 10px 0px;
    /* color: white; */
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #636363;
}
/* fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: "none";
} */
.register_page_form input:focus ,.register_page_form select:focus, .register_page_form textarea:focus{
    outline: none;
}
.register_page_form option {
    line-height: 10px;
    margin: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    padding: 8px 0px 10px 0px;
    color: #636363;
}
.searchWrapper{
  border: none !important;
}
@media only screen and (max-width: 600px) {
  .regis nav .navbar-collapse .navbar-nav .nav-li a{
    color:#000;
  }
}
@media screen and (max-width: 426px) {
  .regis nav .navbar-collapse .navbar-nav .nav-li a{
    color:#fff;
  }
  .regis nav{
    background-color: #000;
  }
  .register_page::before{
    background-color: transparent;
  }
  .register_page_sideborder{
    background-color: #000;
  }
}