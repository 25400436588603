.analytictop-box {
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 100%;
    width: 100%;
    padding: 16px 24px;
}

.analytics-mainbox {
    background: #FFFFFF;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    height: 100%;
    width: 100%;
}

.analytics-mainbox-head {
    background: rgba(226, 232, 240, 0.6);
    border-radius: 12px 12px 0px 0px;
    height: 40px;
    padding-left: 16px;
    display: flex;
    align-items: center;
}

.analytictop-box-img-root {
    margin-top: 13px;
}

.analysis-barchart-root {
    width: 100%;
}

.analysis-allPartners-root {
    padding: 24px;
    width: 100%;
    background: #E2E8F0;
}

.analysis-allPartners-main {
    background-color: white;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 8px 16px;
    height: 100%;
    width: 100%;
}

.allparterns_analytic_total_perticular {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
}
.allparterns_analytic_total_perticular:last-child{
    margin-bottom: 0;
}

.allparterns_analytic_mentor_total_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.allparterns_analytic_mentor_total_body {
    width: 100%;
    height: 4px;
    background: #E8EAED;
    border-radius: 50px;
}

.allparterns_analytic_mentor_total_progress {
    width: 50%;
    height: 100%;
    border-radius: 50px;
}