.participant_feedback_head{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    border-bottom: 2px solid #98B3D6;
}

.participant_feadback_body {
    padding: 40px;
}