.event-card {
  width: 94%;
  background: white;
  border-radius: 4px;
  height: 100%;
  cursor: pointer;
  box-shadow: 0px 3px 20px rgba(48, 48, 48, 0.1);
  border-radius: 12px;
}
a{
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.event-card-main {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* margin: 25px 0; */
  min-height: 300px;
  /* background: #FFFFFF; */
}
.ecard-img-main {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ecard-img-main img {
  width: 100%;
}
.ecard-remtime {
  background: #404654;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  font-weight: 600;
}
.ecard-allDetails {
  /* background: white; */
  padding: 20px;
}
.ecard-etitle {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.75px;
  color: #000000;
}
.ecard-ename-dp {
  display: flex;
  align-items: center;
  margin: 7px 0;
}
.ecard-ename-dp figure {
  margin: 0;
  width: 32px;
  height: 32px;
}
.ecard-ename-dp p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  margin-left: 0px;
}
.ecard-edesc p {
  margin: 10px 0;
  font-size: 14px;
  font-family: "Nunito-light";
  font-weight: 500;
}
.ecard-eventTimedetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}
.ecard-eventTimedetails div {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  /* align-items: center; */
  /* justify-content: center; */
}
.ecard-eventTimedetails div svg {
  font-size: 18px;
  color: #262C5B;
}
.ecard-eventTimedetails div p {
  margin-left: 4px !important;
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  color: #262C5B;
}
.ecard-eventFundDetails {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  height: 33px;
  padding: 0 10px;
  width:90%;
  overflow-x: scroll;
}
.ecard-eventFundDetails::-webkit-scrollbar {
  display: none;
}
.ecard-eventFundDetails div:first-child {
  margin-left: 0;
}

.ecard-eventFundDetails div {
  width: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #cacaca;
  border-radius: 50px;
  height: 28px;
  font-size: 10px;
  font-weight: 500;
  color: #000;
  background: white;
  margin-left: 10px;
}
.item-thumbail-block {
  height: 180px;
  overflow: hidden;
  display: flex;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.item-thumbail {
  max-height: 100%;
  width: 100%;
  object-fit: fill;
}
.event-tags{
  position: absolute;
  top: 10px;
}
@media (max-width: 600px) {
  .event-card {
    width: 100%;
  }
}
