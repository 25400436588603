.target_card_create{
     height: 90%;
     margin-top: 15px;
     min-height: 200px;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     background: white;
     border-radius: 12px;
     box-shadow: 0px 3px 20px rgb(48 48 48 / 10%);
 }
 
.target_card{
     height: 90%;
     margin-top: 10px;
     min-height: 150px;
     background: white;
     border-radius: 12px;
     box-shadow: 0px 3px 20px rgb(48 48 48 / 10%);
 }

 